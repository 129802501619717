import React, { FC, useEffect, useState } from "react"
import TeacherNameInput from "../TeacherNameInput"
import { ApiService } from "service/ApiService"
import { TeacherDataTypes } from "data/types"
import { regularExpressFunction } from "utils/regularExpress"
import { last } from "lodash"

export interface TeacherSearchFormProps {}

const TeacherSearchForm: FC<TeacherSearchFormProps> = () => {
  const [allTeachers, setAllTeachers] = useState<TeacherDataTypes[]>([])
  const [selectedTeacher, setSelectedTeacher] = useState<string>("")
  const [selectedTeacherId, setSelectedTeacherId] = useState<number | null>(
    null
  )
  const [teacherName, setTeacherName] = useState<string | null>("")
  const [tracker, setTracker] = useState<boolean>(false)

  useEffect(() => {
    setSelectedTeacherId(null)
  }, [tracker])

  useEffect(() => {
    ApiService.getAllTeachers()
      .then((res) => setAllTeachers(res.data.data))
      .catch((err) => console.log(err))
  }, [])

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <TeacherNameInput
          buttonIsDisabled={selectedTeacher ? false : true}
          buttonStateData={{
            teacherId: selectedTeacherId
          }}
          data={allTeachers
            .filter((teacher) => {
              const name = `${teacher.firstName} ${teacher.lastName}`
              return name.toLowerCase().includes(selectedTeacher.toLowerCase())
            })
            .slice(0, 5)} // Limiter à 10 résultats visibles
          className="flex-[1.5]"
          value={selectedTeacher}
          onChange={(value: string) => {
            setSelectedTeacher(value)
            setTeacherName(value)
            allTeachers.map((teacher) => {
              if (value === teacher.firstName + " " + teacher.lastName) {
                setSelectedTeacherId(teacher.id)
              } else {
                setTracker((prev) => !prev)
              }
            })
          }}
          buttonSubmitHref={`/search${
            teacherName && selectedTeacherId
              ? `/teacher/${regularExpressFunction(
                  teacherName
                )}/${selectedTeacherId}`
              : ""
          }`}
        />
      </form>
    )
  }

  return renderForm()
}

export default TeacherSearchForm
