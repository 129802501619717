import React, { FC, ReactNode } from "react"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import {useTranslation} from "react-i18next"

export interface SectionHeroProps {
  className?: string
  rightImg: string
  heading: ReactNode
  subHeading: ReactNode
  btnText: string
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText
}) => {
  const { t } = useTranslation("global")
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className={`${
                              t("header.chooseLanguage") === "Ar"
                                ? "text-right"
                                : ""
                            } w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7`}>
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>}
        </div>
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  )
}

export default SectionHero
