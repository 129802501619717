import React from "react"
import { Helmet } from "react-helmet"

const PrivacyPolicy = () => {
  return (
    <div className="nc-PagePrivacyPolicy pt-8 lg:pt-16">
      <Helmet>
        <title>Privacy Policy || Nom de Votre Application</title>
      </Helmet>
      <div className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1 className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl">
            Politique de Confidentialité de CoursPlusPlus
          </h1>
          <p className="text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Date d'efficacité : 1er janvier 2024
          </p>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </div>

      <div className="container space-y-10 my-10">
        <div className="nc-SingleContent max-w-screen-md mx-auto space-y-10">
          <section>
            <h2 className="font-semibold text-2xl">Introduction</h2>
            <p>
              Votre vie privée est cruciale pour nous chez CoursPlusPlus. Cette
              politique de confidentialité explique comment nous collectons,
              utilisons, divulguons, et protégeons vos informations personnelles
              lorsque vous visitez et utilisez notre site web et services à
              www.coursplusplus.com, et vos choix concernant la collecte et
              l'utilisation de vos informations.
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">Collecte d'informations</h2>
            <p>
              Nous collectons des informations vous concernant lorsque vous vous
              inscrivez sur notre site, passez une commande, vous abonnez à
              notre newsletter, répondez à un sondage ou remplissez un
              formulaire.
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">
              Utilisation des informations
            </h2>
            <p>
              Toutes les informations que nous collectons auprès de vous peuvent
              être utilisées pour : Personnaliser votre expérience et répondre à
              vos besoins individuels Améliorer notre site web Améliorer le
              service client et vos besoins de prise en charge Vous contacter
              par e-mail
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">Partage des informations</h2>
            <p>
              Nous sommes les seuls propriétaires des informations collectées
              sur ce site. Vos informations personnelles identifiables ne seront
              pas vendues, échangées, transférées, ou données à une autre
              société pour quelque raison que ce soit, sans votre consentement,
              en dehors de ce qui est nécessaire pour répondre à une demande
              et/ou une transaction, comme pour expédier une commande.
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">Divulgation à des tiers</h2>
            <p>
              Nous ne vendons, n'échangeons, ni ne transférons à des tiers vos
              informations personnellement identifiables. Cela ne comprend pas
              les tiers de confiance qui nous aident à exploiter notre site web,
              à conduire nos affaires, ou à vous servir, tant que ces parties
              conviennent de garder ces informations confidentielles.
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">
              Protection des informations
            </h2>
            <p>
              Nous mettons en œuvre une variété de mesures de sécurité pour
              maintenir la sécurité de vos informations personnelles. Nous
              utilisons un cryptage à la pointe de la technologie pour protéger
              les informations sensibles transmises en ligne. De plus, nous
              protégeons vos informations hors ligne. Seuls les employés qui ont
              besoin d'effectuer un travail spécifique (par exemple, la
              facturation ou le service client) ont accès aux informations
              personnelles identifiables.
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">Consentement</h2>
            <p>
              En utilisant notre site, vous consentez à notre politique de
              confidentialité.
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">
              Modifications de notre politique de confidentialité
            </h2>
            <p>
              Si nous décidons de changer notre politique de confidentialité,
              nous mettrons à jour la date d'efficacité de la politique de
              confidentialité ci-dessus.
            </p>
          </section>
          <section>
            <h2 className="font-semibold text-2xl">Nous contacter</h2>
            <p>
              SSi vous avez des questions concernant cette politique de
              confidentialité, vous pouvez nous contacter en utilisant
              l'information ci-dessous. Email : contact@coursplusplus.com
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
