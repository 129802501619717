import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Page } from "./types"
import ScrollToTop from "./ScrollToTop"
import Footer from "shared/Footer/Footer"
import PageHome from "containers/PageHome/PageHome"
import Page404 from "containers/Page404/Page404"
import ListingPage from "containers/Listing/SearchPage"
import CheckOutPage from "containers/CheckOutPage/CheckOutPage"
import PayPage from "containers/PayPage/PayPage"
import TeacherProfile from "containers/TeacherProfile/TeacherProfile"
import PageContact from "containers/PageContact/PageContact"
import PageAbout from "containers/PageAbout/PageAbout"
import PageSignUp from "containers/PageSignUp/PageSignUp"
import SelectRegiterOption from "containers/PageSignUp/SelectPosition"
import PageLogin from "containers/PageLogin/PageLogin"
import BlogPage from "containers/BlogPage/Blog"
import SiteHeader from "containers/SiteHeader"
import FooterNav from "components/FooterNav"
import useWindowSize from "hooks/useWindowResize"
import ListingStayDetailPage from "containers/ListingDetailPage/listing-details/DetailPage"
import ConfirmEmail from "containers/PageForgetPassword/PageConfirmEmail"
import ConfirmPassword from "containers/PageForgetPassword/PageConfirmPassword"
import PrivacyPolicy from "containers/privacyPolicy/PrivacyPolicy"
import TermsOfUse from "containers/TermsOfUse/TermsOfUse"
import SupportPage from "containers/Support/SupportPage"
import Documentation from "containers/Documentation/Documentation"
import PaymentConfirmationPage from "containers/PayPage/PaymentConfirmationPage"
import VerifyEmail from "containers/PageVerifyMail/VerifyEmail"

export const pages: Page[] = [
  { path: "/", component: PageHome },
  //
  { path: "/search", component: ListingPage },
  {
    path: "/search/studylevel/:studyLevelName?/:studyLevelId?",
    component: ListingPage
  },
  { path: "/search/subject/:subjectName?/:subjectId?", component: ListingPage },
  {
    path: "/search/specialty/:specialName?/:specialId?",
    component: ListingPage
  },
  { path: "/details/:courseName/:courseId", component: ListingStayDetailPage },
  { path: "/search/teacher/:teacherName?/:teacherId?", component: ListingPage },
  //

  {
    path: "/search/studylevel/:studyLevelName?/:studyLevelId?/specialty/:specialName/:specialId?",
    component: ListingPage
  },
  {
    path: "/search/studylevel/:studyLevelName?/:studyLevelId?/subject/:subjectName?/:subjectId?",
    component: ListingPage
  },
  {
    path: "/search/specialty/:specialName?/:specialId?/subject/:subjectName?/:subjectId?",
    component: ListingPage
  },
  {
    path: "/search/studylevel/:studyLevelName?/:studyLevelId?/specialty/:specialName?/:specialId?/subject/:subjectName?/:subjectId?",
    component: ListingPage
  },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  { path: "/payment-confirmation", component: PaymentConfirmationPage },

  //
  { path: "/teacher", component: TeacherProfile },
  { path: "/teacher/:teacherName/:teacherId", component: TeacherProfile },
  //
  { path: "/blog", component: BlogPage },
  //
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/singup_position", component: SelectRegiterOption },
  { path: "/signup/:option", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/forgot_password_email", component: ConfirmEmail },
  { path: "/forgot_password_confirmpassword", component: ConfirmPassword },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-of-use", component: TermsOfUse },
  { path: "/support", component: SupportPage },
  { path: "/documentation", component: Documentation },
  { path: "/verify-email", component: VerifyEmail }

  //
]

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component
          return <Route key={path} element={<Component />} path={path} />
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  )
}

export default MyRoutes
