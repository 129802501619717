import React from "react"
import { Helmet } from "react-helmet"

const SupportPage = () => {
  return (
    <div className="nc-PageSupport pt-8 lg:pt-16">
      <Helmet>
        <title>Support || CoursPlusPlus</title>
      </Helmet>
      <div className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1 className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl">
            Support Client
          </h1>
          <p className="text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Nous sommes là pour vous aider.
          </p>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </div>

      <div className="container space-y-10 my-10">
        <div className="nc-SingleContent max-w-screen-md mx-auto space-y-10">
          {/* Contact Information */}
          <section>
            <h2 className="font-semibold text-2xl">Informations de Contact</h2>
            <p>
              N'hésitez pas à nous contacter si vous avez des questions ou
              besoin d'assistance. Voici comment vous pouvez nous atteindre :
            </p>
            <ul className="list-disc pl-5">
              <li>Email: contact@coursplusplus.com</li>
              <li>Horaires: Lundi - Vendredi, 9h à 17h</li>
            </ul>
          </section>

          {/* FAQ */}
          <section>
            <h2 className="font-semibold text-2xl">FAQ</h2>
            <p>
              Vous avez une question? Trouvez rapidement des réponses dans notre
              FAQ.
            </p>
            {/* Assume there's a component <FAQItem> that displays a question and its answer when clicked */}
            {/* <FAQItem question="Comment m'inscrire à un cours ?" answer="Pour vous inscrire à un cours, ..." />
            <FAQItem question="Comment puis-je payer pour un cours ?" answer="Nous acceptons les paiements par ..." /> */}
          </section>

          {/* Additional Resources */}
          <section>
            <h2 className="font-semibold text-2xl">
              Ressources Supplémentaires
            </h2>
            <p>
              Explorez nos guides et articles pour obtenir plus d'informations
              et de conseils sur l'utilisation de notre plateforme.
            </p>
            {/* Links to resources, guides, blog posts, etc. */}
          </section>

          {/* Live Chat / Support Tickets */}
          <section>
            <h2 className="font-semibold text-2xl">
              Chat en Direct / Tickets de Support
            </h2>
            <p>
              Notre équipe de support est disponible via chat en direct ou peut
              être contactée par la soumission d'un ticket de support.
            </p>
            {/* Instructions or direct integration for live chat or support ticket submission */}
          </section>
        </div>
      </div>
    </div>
  )
}

export default SupportPage
