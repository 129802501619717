import React, { FC, useEffect, useRef, useState } from "react"
import Logo from "shared/Logo/Logo"
import Navigation from "shared/Navigation/Navigation"
import ButtonPrimary, {
  ButtonBlue,
  ButtonGreen,
  ButtonYellow
} from "shared/Button/ButtonPrimary"
import MenuBar from "shared/MenuBar/MenuBar"
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode"
import HeroSearchForm2Mobile from "components/HeroSearchForm2Mobile/HeroSearchForm2Mobile"
import { useAuth } from "service/Auth"
import { useTranslation } from "react-i18next"
import { IoIosArrowDown } from "react-icons/io"
import { Fr, Ma } from "react-flags-select"

export interface MainNav1Props {
  className?: string
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, clearSession } = useAuth()
  const { t, i18n } = useTranslation("global")

  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownRefMobile = useRef<HTMLDivElement>(null)

  const [isLanguageOpen, setIsLanguageOpen] = useState<boolean>(false)
  const [isLanguageOpenMobile, setIsLanguageOpenMobile] =
    useState<boolean>(false)

  const handleLogout = () => {
    clearSession()
  }

  const selectedLanguage = t("header.chooseLanguage")

  const renderLanguageOption = () => {
    if (selectedLanguage === "Ar") {
      return (
        <>
          {t("header.languageOptions.arabic")} <Ma />
        </>
      )
    } else if (selectedLanguage === "Fr") {
      return (
        <>
          {t("header.languageOptions.french")} <Fr />
        </>
      )
    } else {
      return null
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsLanguageOpen(false)
    }
  }

  const handleClickOutsideMobile = (event: MouseEvent) => {
    if (
      dropdownRefMobile.current &&
      !dropdownRefMobile.current.contains(event.target as Node)
    ) {
      setIsLanguageOpenMobile(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("mousedown", handleClickOutsideMobile)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("mousedown", handleClickOutsideMobile)
    }
  }, [])

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-2 lg:py-2 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2Mobile />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <div className="relative" ref={dropdownRef}>
              <div
                className="flex items-center gap-2 text-lg cursor-pointer"
                onClick={() => setIsLanguageOpen(!isLanguageOpen)}
              >
                {renderLanguageOption()}{" "}
                <IoIosArrowDown
                  className={`${
                    isLanguageOpen ? "rotate-[-180deg]" : ""
                  } transition-[0.9s]`}
                />
              </div>

              <div
                className={`absolute bg-white text-black text-lg px-4 py-2 right-0 top-[110%] rounded-md w-[130px] ${
                  isLanguageOpen ? "block" : "hidden"
                }`}
                onClick={() => setIsLanguageOpen(!isLanguageOpen)}
              >
                <button
                  onClick={() => i18n.changeLanguage("ar")}
                  className="flex items-center gap-2 w-full"
                >
                  <span className="flex-1 flex justify-start">
                    {t("header.languageOptions.arabic")}
                  </span>
                  <div>
                    <Ma />
                  </div>
                </button>
                <button
                  onClick={() => i18n.changeLanguage("fr")}
                  className="flex items-center gap-2 w-full"
                >
                  <span className="flex-1 flex justify-start">
                    {t("header.languageOptions.french")}
                  </span>
                  <div className="w-[30px] flex justify-end">
                    <Fr />
                  </div>
                </button>
              </div>
            </div>
            <SwitchDarkMode />
            <div className="px-1 flex gap-4" />
            {user?.token ? (
              <div className="flex gap-3">
                <ButtonPrimary href="#" onClick={handleLogout}>
                  {t("header.buttons.logout")}
                </ButtonPrimary>
                <ButtonYellow href="https://dashboard.coursplusplus.com/">
                  {t("header.buttons.dashboard")}
                </ButtonYellow>
              </div>
            ) : (
              <div className="flex gap-3">
                <ButtonPrimary href="/signup/teacher">
                  {t("header.buttons.becomeTeacher")}
                </ButtonPrimary>
                <ButtonYellow href="https://dashboard.coursplusplus.com/">
                  {t("header.buttons.dashboard")}
                </ButtonYellow>
              </div>
            )}
          </div>

          <div className="flex xl:hidden items-center">
            <div className="relative" ref={dropdownRefMobile}>
              <div
                className="flex items-center gap-2 text-lg cursor-pointer"
                onClick={() => setIsLanguageOpenMobile(!isLanguageOpenMobile)}
              >
                {renderLanguageOption()}{" "}
                <IoIosArrowDown
                  className={`${
                    isLanguageOpenMobile ? "rotate-[-180deg]" : ""
                  } transition-[0.9s]`}
                />
              </div>

              <div
                className={`absolute bg-white text-black text-lg px-4 py-2 right-0 top-[110%] rounded-md w-[130px] ${
                  isLanguageOpen ? "block" : "hidden"
                }`}
                onClick={() => setIsLanguageOpen(!isLanguageOpen)}
              >
                <button
                  onClick={() => i18n.changeLanguage("ar")}
                  className="flex items-center gap-2 w-full"
                >
                  <span className="flex-1 flex justify-start">
                    {t("header.languageOptions.arabic")}
                  </span>
                  <div>
                    <Ma />
                  </div>
                </button>
                <button
                  onClick={() => i18n.changeLanguage("fr")}
                  className="flex items-center gap-2 w-full"
                >
                  <span className="flex-1 flex justify-start">
                    {t("header.languageOptions.french")}
                  </span>
                  <div className="w-[30px] flex justify-end">
                    <Fr />
                  </div>
                </button>
              </div>
            </div>
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainNav1
