import React, { useEffect, useRef, useState } from "react"
import ButtonClose from "shared/ButtonClose/ButtonClose"
import Logo from "shared/Logo/Logo"
import { NavLink } from "react-router-dom"
import ButtonPrimary, { ButtonYellow } from "shared/Button/ButtonPrimary"
import SocialsList from "shared/SocialsList/SocialsList"
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode"
import { useAuth } from "service/Auth"
import { useTranslation } from "react-i18next"
import { IoIosArrowDown } from "react-icons/io"
import { Ma, Fr } from "react-flags-select"

export interface NavMobileProps {
  onClickClose?: () => void
}

const NavMobile: React.FC<NavMobileProps> = ({ onClickClose }) => {
  const { clearSession, user } = useAuth()
  const { t, i18n } = useTranslation("global")

  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isLanguageOpen, setIsLanguageOpen] = useState<boolean>(false)

  const selectedLanguage = t("header.chooseLanguage")

  const renderLanguageOption = () => {
    if (selectedLanguage === "Ar") {
      return (
        <>
          {t("header.languageOptions.arabic")} <Ma />
        </>
      )
    } else if (selectedLanguage === "Fr") {
      return (
        <>
          {t("header.languageOptions.french")} <Fr />
        </>
      )
    } else {
      return null
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsLanguageOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5 flex flex-col items-center">
        {/* Bloc supérieur avec langue à gauche, logo et description */}
        <div className="flex items-center justify-between w-full">
          {/* Bloc de choix de la langue à gauche */}
          <div className="relative" ref={dropdownRef}>
            <div
              className="flex items-center gap-2 text-lg cursor-pointer"
              onClick={() => setIsLanguageOpen(!isLanguageOpen)}
            >
              {renderLanguageOption()}{" "}
              <IoIosArrowDown
                className={`${
                  isLanguageOpen ? "rotate-[-180deg]" : ""
                } transition-[0.9s]`}
              />
            </div>

            {/* Dropdown des options de langue */}
            <div
              className={`absolute bg-white text-black text-lg px-4 py-2 left-0 top-[110%] rounded-md w-[130px] ${
                isLanguageOpen ? "block" : "hidden"
              }`}
              onClick={() => setIsLanguageOpen(!isLanguageOpen)}
            >
              <button
                onClick={() => i18n.changeLanguage("ar")}
                className="flex items-center gap-2 w-full"
              >
                <span className="flex-1 flex justify-start">
                  {t("header.languageOptions.arabic")}
                </span>
                <Ma />
              </button>
              <button
                onClick={() => i18n.changeLanguage("fr")}
                className="flex items-center gap-2 w-full"
              >
                <span className="flex-1 flex justify-start">
                  {t("header.languageOptions.french")}
                </span>
                <Fr />
              </button>
            </div>
          </div>

          {/* Logo centré */}
          <Logo />

          {/* Bouton de fermeture */}
          <span className="absolute right-2 top-2 p-1">
            <ButtonClose onClick={onClickClose} />
          </span>
        </div>

        {/* Texte de description sous le logo */}
        <div className="mt-4 text-center text-neutral-700 dark:text-neutral-300 text-sm">
          <span>{t("header.mobileDescription")}</span>
        </div>
      </div>

      {/* Contenu principal */}
      <ul className="flex flex-col py-6 px-2 space-y-1">
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/"}
        >
          <span className={`py-2.5 pr-3`}>{t("header.navLinks.home")}</span>
        </NavLink>
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/about"}
        >
          <span className={`py-2.5 pr-3`}>{t("header.navLinks.about")}</span>
        </NavLink>
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/contact"}
        >
          <span className={`py-2.5 pr-3`}>{t("header.navLinks.contact")}</span>
        </NavLink>
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/blog"}
        >
          <span className={`py-2.5 pr-3`}>{t("header.navLinks.blog")}</span>
        </NavLink>
        {user && (
          <NavLink
            end
            className={({ isActive }) =>
              `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
                isActive
                  ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                  : ""
              }`
            }
            to={"#"}
            onClick={clearSession}
          >
            <span className={`py-2.5 pr-3`}>{t("header.buttons.logout")}</span>
          </NavLink>
        )}
      </ul>

      {/* Boutons en bas */}
      <div className="flex items-center justify-between py-8 px-5">
        {user ? (
          <div className="flex flex-col space-y-4 w-full">
            <ButtonYellow href={"https://dashboard.coursplusplus.com/"}>
              {t("header.buttons.dashboard")}
            </ButtonYellow>
          </div>
        ) : (
          <div className="flex flex-col space-y-4 w-full">
            <ButtonPrimary className="w-full" href="/signup/teacher">
              {t("header.buttons.becomeTeacher")}
            </ButtonPrimary>
            <ButtonYellow
              className="w-full"
              href={"https://dashboard.coursplusplus.com/"}
            >
              {t("header.buttons.dashboard")}
            </ButtonYellow>
          </div>
        )}
      </div>
    </div>
  )
}

export default NavMobile
