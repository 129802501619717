import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useRef,
  useState
} from "react"
import { Helmet } from "react-helmet"
import Input from "shared/Input/Input"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import { Link, useParams, useNavigate } from "react-router-dom"
import {
  RegisterFormTypes,
  SpecialtiesTypes,
  StudyLevelsTypes
} from "data/types"
import { ApiService } from "service/ApiService"
import swal from "sweetalert"
import { MdEdit } from "react-icons/md"
import placeholder_image from "../../images/placeholder-small.png"
import Select from "shared/Select/Select"
import { useTranslation } from "react-i18next"

export interface PageSignUpProps {
  className?: string
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useTranslation("global")

  const { option } = useParams()
  const navigate = useNavigate()
  const [stdErr, setStdErr] = useState<boolean>(false)
  const [speErr, setSpeErr] = useState<boolean>(false)
  const [c_password_error, setC_password_error] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [studyLevels, setStudyLevels] = useState<StudyLevelsTypes[]>([])
  const [Specialties, setSpecialties] = useState<SpecialtiesTypes[]>([])
  const [showAlert, setShowAlert] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState("")

  useEffect(() => {
    option === "student" ? setPage(1) : setPage(2)

    ApiService.getAllStudyLevels()
      .then((res) => {
        setStudyLevels(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const [form, setForm] = useState<RegisterFormTypes>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: 0,
    address: "",
    city: "",
    studyLevelId: 0,
    specialtyId: 0,
    photos: null,
    gender: "",
    birthDate: "",
    aboutMe: ""
  })

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleUploadProfile = (e: ChangeEvent<HTMLInputElement>) => {
    let image: any
    if (e.target.files) image = e.target.files[0]

    setForm((prev) => ({
      ...prev,
      photos: image
    }))
  }

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target

    setForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleStudyLevelSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target

    studyLevels.find((std) =>
      std.id === Number(value) ? setSpecialties(std.specialties) : ""
    )

    setForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleRegisterSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    const phoneRegex = /^\d{10}$/
    if (!phoneRegex.test(form.phone.toString())) {
      setShowErrorMessage(t("form.invalidPhone"))
      setShowAlert(true)
      return
    }

    if (!passwordRegex.test(form.password)) {
      setShowErrorMessage(t("form.invalidPassword"))
      setShowAlert(true)
      return
    }

    const today = new Date() // Date actuelle
    const birthDate = new Date(form.birthDate) // Date de naissance saisie
    let age = today.getFullYear() - birthDate.getFullYear() // Utiliser let pour pouvoir modifier l'âge
    const monthDiff = today.getMonth() - birthDate.getMonth()
    const dayDiff = today.getDate() - birthDate.getDate()

    // Ajuster l'âge si l'anniversaire n'est pas encore passé cette année
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--
    }

    // Validation de la date de naissance
    if (birthDate > today) {
      setShowErrorMessage(t("form.invalidBirthDateFuture")) // Date dans le futur
      setShowAlert(true)
      return
    }

    if (age < 6) {
      setShowErrorMessage(t("form.invalidBirthDateTooYoung")) // Trop jeune
      setShowAlert(true)
      return
    }

    if (age > 70) {
      setShowErrorMessage(t("form.invalidBirthDateTooOld")) // Trop âgé
      setShowAlert(true)
      return
    }

    if (form.password !== form.confirmPassword) {
      setC_password_error(true)
      return
    } else {
      setC_password_error(false)
      setIsLoading(true)

      if (option === "teacher") {
        ApiService.signupTeacher(
          form.firstName,
          form.lastName,
          form.email,
          form.password,
          form.phone,
          form.address,
          form.city,
          form.photos,
          form.birthDate,
          form.gender,
          form.aboutMe
        )
          .then((res) => {
            setIsLoading(false)

            if (res.data.status === "FAIL") {
              setShowAlert(true)
              if (res.data.error.includes("email address is already in use")) {
                setShowErrorMessage(t("form.emailInUse"))
              } else {
                setShowErrorMessage(t("form.otherErrors"))
              }
            } else {
              setShowAlert(false)
              swal({
                title: "Congratulation 🎉",
                text: res.data.data,
                icon: "success"
              })
                .then(() => navigate("/login"))
                .catch(() => navigate("/login"))
            }
          })
          .catch((err) => {
            setIsLoading(false)
            setShowAlert(true)
            if (
              err.response.data.error.includes(
                "email address is already in use"
              )
            ) {
              setShowErrorMessage(t("form.emailInUse"))
            } else {
              setShowErrorMessage(t("form.otherErrors"))
            }
          })
      } else if (option === "student") {
        ApiService.signupStudent(
          form.firstName,
          form.lastName,
          form.email,
          form.password,
          form.phone,
          form.address,
          form.city,
          form.photos,
          form.studyLevelId,
          form.specialtyId,
          form.birthDate,
          form.gender
        )
          .then((res) => {
            setIsLoading(false)

            if (res.data.status === "FAIL") {
              setShowAlert(true)
              if (res.data.error.includes("email address is already in use")) {
                setShowErrorMessage(t("form.emailInUse"))
              } else {
                setShowErrorMessage(t("form.otherErrors"))
              }
            } else {
              setShowAlert(false)
              swal({
                title: "Congratulation 🎉",
                text: res.data.data,
                icon: "success"
              })
                .then(() => navigate("/login"))
                .catch(() => navigate("/login"))
            }
          })
          .catch((err) => {
            setIsLoading(false)
            setShowAlert(true)
            if (
              err.response.data.error.includes(
                "email address is already in use"
              )
            ) {
              setShowErrorMessage(t("form.emailInUse"))
            } else {
              setShowErrorMessage(t("form.otherErrors"))
            }
          })
      }
    }
  }

  const handleContinue = () => {
    if (form.studyLevelId === 0) {
      setStdErr(true)
      return
    } else {
      setStdErr(false)
    }

    if (form.specialtyId === 0) {
      setSpeErr(true)
      return
    } else {
      setSpeErr(false)
    }

    setPage(2)
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{t("signUp.title")}</title>
      </Helmet>
      <div className="container mb-14 lg:mb-20 ">
        <div className="max-w-2xl mt-10 m-auto border border-neutral-200 dark:border-neutral-700 px-4 py-8 rounded-xl relative">
          <div className="absolute top-4 left-4"></div>
          {showAlert && (
            <div
              className="bg-red-100 border-l-4 border-red-500 text-yellow-700 p-2 rounded relative max-w-md mx-auto mb-4"
              role="alert"
            >
              <strong className="block font-bold sm:inline">
                {showErrorMessage}
              </strong>
              <button
                type="button"
                className="absolute top-0 right-0 mt-2 mr-2"
                onClick={() => setShowAlert(false)}
              >
                <span className="text-yellow-700">&times;</span>
              </button>
            </div>
          )}
          <h2 className="mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {option === "student" ? t("form.study_path") : t("form.teaching")}
          </h2>

          <div className="max-w-xl mx-auto space-y-2  ">
            {/* FORM */}
            <form
              className="grid grid-cols-1 gap-6"
              onSubmit={handleRegisterSubmit}
            >
              {page === 1 ? (
                <>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.study_level")}
                    </span>
                    <Select
                      name="studyLevelId"
                      className={`mt-1 ${stdErr ? "!border-red-500" : ""}`}
                      onChange={handleStudyLevelSelect}
                    >
                      <option value="" selected>
                        {t("form.select_option")}{" "}
                      </option>
                      {studyLevels.map((std, idx) => (
                        <option key={idx} value={std.id}>
                          {std.name}
                        </option>
                      ))}
                    </Select>
                  </label>

                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.specialty")}
                    </span>
                    <Select
                      name="specialtyId"
                      className={`mt-1 ${speErr ? "!border-red-500" : ""}`}
                      onChange={handleSelectChange}
                    >
                      <option value="" selected>
                        {t("form.select_option")}{" "}
                      </option>
                      {Specialties.map((std, idx) => (
                        <option key={idx} value={std.id}>
                          {std.name}
                        </option>
                      ))}
                    </Select>
                  </label>

                  <div
                    onClick={handleContinue}
                    className="ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 flex justify-center items-center p-3 rounded-full"
                  >
                    {t("form.continue")}
                  </div>
                </>
              ) : (
                <>
                  {/* Image */}
                  <div className="m-auto relative">
                    <div className="w-[200px] h-[200px] rounded-full overflow-hidden">
                      <img
                        className="w-full"
                        src={
                          form.photos
                            ? URL.createObjectURL(form.photos)
                            : placeholder_image
                        }
                        alt="user avtar"
                      />
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleUploadProfile}
                        id="image"
                        accept=".jpg,.png,.jpeg"
                      />
                    </div>
                    <div
                      onClick={() => document.getElementById("image")?.click()}
                      className="w-[40px] h-[40px] overflow-hidden rounded-full absolute right-2 bottom-2 border dark:bg-primary-6000 bg-white flex justify-center items-center shadow-lg cursor-pointer"
                    >
                      <MdEdit className="text-2xl" />
                    </div>
                  </div>
                  {/* First Name */}
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.firstName")}
                    </span>
                    <Input
                      type="text"
                      placeholder={t("form.firstNamePlaceholder")}
                      className="mt-1"
                      name="firstName"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* Last Name */}
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.lastName")}
                    </span>
                    <Input
                      type="text"
                      placeholder={t("form.lastNamePlaceholder")}
                      className="mt-1"
                      name="lastName"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* Email */}
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.email")}
                    </span>
                    <Input
                      type="email"
                      placeholder="votre email"
                      className="mt-1"
                      name="email"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* Phone */}
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.phone")}
                    </span>
                    <Input
                      type="text"
                      placeholder={t("form.phonePlaceholder")}
                      className="mt-1"
                      name="phone"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* Gender */}
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.gender")}
                    </span>
                    <Select
                      name="gender"
                      className={`mt-1`}
                      onChange={handleSelectChange}
                      required={true}
                    >
                      <option value="" disabled selected>
                        {t("form.select")}{" "}
                      </option>
                      <option value={"MALE"}>{t("form.male")}</option>{" "}
                      <option value={"FEMALE"}>{t("form.female")}</option>{" "}
                    </Select>
                  </label>
                  {/* birthdate */}
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("form.birthDate")}
                    </span>
                    <Input
                      type="date"
                      className="mt-1"
                      name="birthDate"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* address */}
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t("form.address")}
                    </span>
                    <Input
                      type="text"
                      className="mt-1"
                      placeholder={t("form.addressPlaceholder")}
                      name="address"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* City */}
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t("form.city")}
                    </span>
                    <Input
                      type="text"
                      className="mt-1"
                      name="city"
                      placeholder={t("form.cityPlaceholder")}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* about me */}
                  {option === "teacher" && (
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        {t("form.aboutMe")}
                      </span>
                      <Input
                        type="text"
                        className="mt-1"
                        placeholder={t("form.aboutMePlaceholder")}
                        name="aboutMe"
                        onChange={handleInputChange}
                        maxLength={200}
                        required
                      />
                    </label>
                  )}
                  {/* Password */}
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t("form.password")}
                    </span>
                    <Input
                      type="password"
                      className="mt-1"
                      name="password"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  {/* Confirm Password */}
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t("form.confirmPassword")}
                    </span>
                    <Input
                      type="password"
                      className={`mt-1 ${
                        c_password_error ? "!border-red-500" : ""
                      }`}
                      name="confirmPassword"
                      onChange={handleInputChange}
                      required
                    />
                    <span className="text-red-500">
                      {c_password_error ? "Password is not Match" : ""}
                    </span>
                  </label>
                  {showErrorMessage && (
                    <div className="text-red-500 text-center mb-4">
                      {showErrorMessage}
                    </div>
                  )}
                  {isLoading ? (
                    <ButtonPrimary
                      disabled
                      type="button"
                      className="!cursor-not-allowed !pointer-events-none !bg-gray-500"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#1C64F2"
                        />
                      </svg>
                      Loading...
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary type="submit">S'inscrire</ButtonPrimary>
                  )}
                </>
              )}
            </form>
            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              {t("signUp.haveAccount")}{" "}
              <Link to="/login">{t("signUp.signInLink")}</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageSignUp
