import React from "react";

const LogoSvgLight = () => {
  return (
    <svg
      className="w-full hidden dark:block"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 500.00 500.00"
    >
      <path
        fill="#5046e5"
        d="   M 176.47 187.82   C 157.64 178.85 135.91 177.05 118.07 188.58   C 109.12 194.36 101.25 203.16 96.94 212.94   Q 86.63 236.33 97.57 259.26   C 109.71 284.69 132.62 293.25 159.37 290.51   Q 159.92 290.45 159.96 291.00   Q 160.70 301.06 159.90 312.24   C 159.58 316.74 153.14 318.94 149.58 319.05   Q 145.21 319.18 141.01 318.86   C 109.87 316.47 86.89 300.13 72.82 273.09   Q 55.20 239.25 70.64 202.75   Q 74.68 193.19 79.54 187.00   Q 95.64 166.47 119.39 157.41   C 146.71 146.99 176.24 152.01 199.16 170.21   A 0.31 0.30 45.5 0 1 199.16 170.68   L 177.64 187.68   Q 177.10 188.12 176.47 187.82   Z"
      />
      <path
        fill="#f2f3f7"
        d="   M 328.14 281.45   Q 328.31 287.78 327.15 292.12   C 325.66 297.68 320.04 301.51 314.87 303.65   Q 308.84 306.14 302.78 308.47   A 1.97 1.97 0.0 0 1 300.10 306.67   C 299.63 278.97 299.00 251.39 300.36 222.74   Q 301.49 198.92 319.17 182.98   C 335.43 168.31 356.25 163.05 377.35 168.55   C 394.78 173.09 410.02 185.35 417.93 201.78   Q 422.51 211.29 423.88 221.86   C 424.60 227.38 424.53 231.97 423.59 237.50   C 418.99 264.70 400.77 282.63 374.60 289.04   Q 360.82 292.42 345.50 288.60   C 340.61 287.38 337.45 284.38 337.41 278.98   Q 337.33 267.66 337.69 256.70   A 1.52 1.52 0.0 0 1 338.50 255.41   Q 339.39 254.94 340.38 255.45   C 342.44 256.50 344.20 258.02 346.39 258.80   C 359.06 263.30 372.65 264.00 383.14 254.44   Q 388.59 249.47 392.00 243.59   C 396.53 235.76 396.30 225.92 393.46 217.36   Q 391.45 211.27 386.47 206.26   C 377.27 197.00 365.17 191.79 352.62 195.94   Q 335.03 201.77 330.05 217.88   Q 328.06 224.30 327.87 236.04   Q 327.50 258.54 328.14 281.45   Z"
      />
      <path
        fill="#f2f3f7"
        d="   M 232.20 269.15   Q 249.29 268.61 259.07 255.34   C 274.41 234.52 260.47 204.02 234.53 202.04   Q 218.41 200.81 207.36 212.19   Q 202.47 217.23 200.13 223.49   Q 198.40 228.10 198.13 235.49   Q 197.73 246.28 197.84 256.29   Q 197.94 265.02 197.65 273.56   Q 197.37 281.90 197.50 290.48   C 197.61 298.68 194.91 306.58 187.06 310.30   Q 181.33 313.02 174.27 314.64   Q 173.19 314.89 172.21 314.74   A 1.58 1.58 0.0 0 1 170.88 313.27   Q 170.51 306.35 170.40 299.75   Q 170.11 283.54 169.69 266.09   C 169.38 253.68 169.62 241.83 170.82 229.94   Q 171.07 227.41 171.97 221.44   C 173.72 209.76 179.64 198.24 188.68 190.50   C 195.59 184.58 202.89 179.55 211.13 176.86   C 228.85 171.05 249.03 174.44 264.75 183.66   Q 284.37 195.17 290.99 218.19   Q 294.82 231.51 292.33 247.80   Q 290.75 258.09 285.81 266.38   Q 277.93 279.61 265.09 287.61   Q 243.01 301.37 217.76 296.22   C 210.84 294.81 207.19 291.53 207.63 283.97   Q 208.23 273.70 207.70 263.49   A 1.21 1.21 0.0 0 1 209.22 262.26   Q 211.74 262.94 213.85 264.35   C 219.10 267.87 226.01 269.34 232.20 269.15   Z"
      />
    </svg>
  );
};

export default LogoSvgLight;
