import React, { FC, useEffect, useState } from "react"
import StudyLevelInput from "../StudyLevelInput"
import SpecilatiesInput from "../SpecilatiesInput"
import SubjectsInput from "../SubjectsInput"
import { ApiService } from "service/ApiService"
import { SpecialtiesTypes, StudyLevelsTypes } from "data/types"
import { useTranslation } from "react-i18next"

interface props {
  setStudyLevelId: (val: number) => void
  setSpecilatyId: (val: number) => void
  setSubjectId: (val: number) => void
  setSpcialName: (val: string) => void
  setStudyLevelName: (val: string) => void
  setSubjectName: (val: string) => void
}

const SearchByCourse: FC<props> = ({
  setStudyLevelId = () => {},
  setSpecilatyId = () => {},
  setSubjectId = () => {},
  setSpcialName = () => {},
  setStudyLevelName = () => {},
  setSubjectName = () => {}
}) => {
  const { t } = useTranslation("global")
  const [fieldNameShow, setFieldNameShow] = useState<
    "study_level" | "specilaties" | "subjects" | ""
  >("")
  const [studyLevel, setStudyLevel] = useState("")
  const [specilality, setSpecilality] = useState("")
  const [subject, setSubject] = useState("")
  const [helperSpecialties, setHelperSpecialties] = useState<
    SpecialtiesTypes[]
  >([])

  useEffect(() => {
    if (studyLevel) {
      ApiService.getAllStudyLevels()
        .then((res) => {
          res.data.data.forEach((std_level: StudyLevelsTypes) => {
            if (studyLevel === std_level.name) {
              setHelperSpecialties(std_level.specialties || [])
            }
          })
        })
        .catch((err) => console.log(err))
    }
  }, [studyLevel])

  const renderStudyLevelInput = () => {
    const isActive = fieldNameShow === "study_level"
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className="w-full flex justify-between text-sm font-medium p-4"
            onClick={() => setFieldNameShow("study_level")}
          >
            <span className="text-neutral-400">
              {t("studyLevels.headingText")}
            </span>
            <span>{studyLevel || t("studyLevels.defaultValue")}</span>
          </button>
        ) : (
          <StudyLevelInput
            defaultValue={studyLevel}
            onChange={(value, id) => {
              setStudyLevel(value)
              setStudyLevelName(value)
              setStudyLevelId(id)
              setFieldNameShow("specilaties")
            }}
          />
        )}
      </div>
    )
  }

  const renderSpecilatiesInput = () => {
    const isActive = fieldNameShow === "specilaties"
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className="w-full flex justify-between text-sm font-medium p-4"
            onClick={() => setFieldNameShow("specilaties")}
          >
            <span className="text-neutral-400">
              {t("specilatiesInput.headingText")}
            </span>
            <span>{specilality || t("specilatiesInput.defaultValue")}</span>
          </button>
        ) : (
          <SpecilatiesInput
            helperSpecialties={helperSpecialties}
            defaultValue={specilality}
            onChange={(value, id) => {
              setSpecilality(value)
              setSpecilatyId(id)
              setSpcialName(value)
              setFieldNameShow("subjects")
            }}
          />
        )}
      </div>
    )
  }

  const renderSubjectsInput = () => {
    const isActive = fieldNameShow === "subjects"

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("subjects")}
          >
            <span className="text-neutral-400">
              {t("subjectsInput.headingText")}
            </span>
            <span>{subject || t("subjectsInput.defaultValue")}</span>
          </button>
        ) : (
          <SubjectsInput
            defaultValue={subject}
            onChange={(value, id) => {
              setSubject(value)
              setSubjectName(value)
              setSubjectId(id)
              setFieldNameShow("")
            }}
          />
        )}
      </div>
    )
  }

  return (
    <div className="w-full space-y-5">
      {renderSubjectsInput()}
      {renderStudyLevelInput()}
      {renderSpecilatiesInput()}
    </div>
  )
}

export default SearchByCourse
