import React from "react";

const LogoSvg = () => {
  return (
    <svg
      className="w-full block dark:hidden"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 500.00 500.00"
    >
      <path
        fill="#5046e4"
        d="   M 96.83 213.14   C 87.18 234.68 92.46 258.08 108.38 275.06   C 121.32 288.87 141.27 292.59 159.24 290.49   Q 159.94 290.41 159.98 291.11   Q 160.78 304.81 159.73 313.02   C 159.24 316.93 152.43 319.07 149.17 319.11   Q 146.39 319.14 143.83 319.02   Q 108.09 317.38 85.86 292.29   C 74.24 279.17 65.90 262.11 63.86 244.27   C 61.31 222.06 69.72 196.19 85.57 179.93   C 116.70 148.00 163.47 141.77 199.16 170.22   A 0.31 0.30 -45.5 0 1 199.16 170.69   L 177.42 187.89   A 0.55 0.54 38.3 0 1 176.83 187.95   C 163.88 181.63 148.62 178.78 135.00 181.61   C 118.37 185.08 103.76 197.67 96.83 213.14   Z"
      />
      <path
        fill="#19181d"
        d="   M 355.18 195.57   Q 335.93 199.81 329.95 217.90   Q 328.06 223.60 327.82 232.75   Q 327.06 261.16 328.07 281.88   Q 328.43 289.16 327.01 292.67   Q 324.16 299.69 317.33 302.61   Q 310.26 305.64 303.26 308.44   A 2.32 2.32 0.0 0 1 300.08 306.29   Q 300.03 291.33 299.66 275.76   Q 298.99 247.24 300.51 221.91   Q 301.33 208.26 307.80 196.96   C 320.13 175.44 346.40 163.09 370.91 167.14   Q 388.69 170.08 401.50 180.84   C 423.09 198.96 429.53 228.40 418.53 254.15   C 410.67 272.53 392.71 285.59 373.35 289.48   Q 358.55 292.46 344.68 288.29   C 339.55 286.75 337.06 283.50 337.28 278.19   Q 337.76 266.46 337.47 255.13   Q 337.45 254.30 338.18 254.70   Q 347.71 259.96 357.09 261.60   C 371.97 264.20 383.89 257.00 391.36 244.70   Q 396.60 236.09 395.44 226.14   Q 394.37 217.02 390.27 210.80   Q 387.25 206.23 380.87 201.51   C 373.29 195.90 364.24 193.57 355.18 195.57   Z"
      />
      <path
        fill="#19181d"
        d="   M 291.46 251.50   C 285.53 277.36 260.99 296.21 235.03 297.64   Q 226.10 298.13 216.58 295.91   C 210.71 294.55 207.00 290.90 207.47 284.84   C 207.88 279.67 208.14 274.60 207.87 269.44   Q 207.66 265.50 207.83 261.81   A 0.38 0.38 0.0 0 1 208.41 261.50   Q 213.63 264.65 218.77 266.73   C 235.63 273.57 254.54 266.34 262.28 249.97   C 267.39 239.15 266.10 224.73 258.29 215.00   C 240.33 192.65 203.95 200.36 198.45 229.20   Q 197.50 234.16 197.86 240.87   C 197.90 241.57 197.53 242.29 197.53 243.01   Q 197.52 265.89 197.46 289.75   C 197.44 295.54 196.38 302.56 192.14 306.82   C 187.01 311.98 178.52 313.78 171.47 315.18   A 0.37 0.37 0.0 0 1 171.03 314.81   Q 171.11 310.17 170.94 305.49   Q 170.20 284.88 169.67 263.94   Q 169.15 243.11 171.60 224.20   Q 175.02 197.89 196.20 183.84   C 216.56 170.33 243.10 171.36 264.04 183.15   C 287.96 196.62 297.49 225.23 291.46 251.50   Z"
      />
    </svg>
  );
};

export default LogoSvg;
