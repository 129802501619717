import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Tab, Transition } from "@headlessui/react"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { XMarkIcon } from "@heroicons/react/24/solid"
import ButtonSubmit from "./ButtonSubmit"
import { useTimeoutFn } from "react-use"
import SearchByTeacher from "./(search-forms)/SearchByTeacher"
import SearchByCourse from "./(search-forms)/SearchByCourse"
import { CheckUrl } from "utils/getUrl"
import { useTranslation } from "react-i18next"

const HeroSearchForm2Mobile = () => {
  const { t } = useTranslation("global")
  const [showModal, setShowModal] = useState(false)

  const [currentTab, setCurrentTab] = useState<string>(t("heroSearch.all"))

  const [studyLevelId, setStudyLevelId] = useState<number | null>(null)
  const [specilatyId, setSpecilatyId] = useState<number | null>(null)
  const [subjectId, setSubjectId] = useState<number | null>(null)
  const [teacherId, setTeacherId] = useState<number | null>(null)

  const [url, setUrl] = useState<string>("/search")

  const [studyLevelName, setStudyLevelName] = useState<string | null>("")
  const [specialName, setSpcialName] = useState<string | null>("")
  const [subjectName, setSubjectName] = useState<string | null>("")
  const [teacherName, setTeacherName] = useState<string | null>("")

  const [showDialog, setShowDialog] = useState(false)
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1)

  useEffect(() => {
    setStudyLevelId(null)
    setSpecilatyId(null)
    setSubjectId(null)
    setTeacherId(null)

    setStudyLevelName(null)
    setSpcialName(null)
    setSubjectName(null)
    setTeacherName(null)

    setCurrentTab(t("heroSearch.all"))

    CheckUrl(null, null, null, null, null, null, null, null)
  }, [showModal, showDialog, currentTab])

  useEffect(() => {
    setUrl(
      (prev) =>
        (prev = CheckUrl(
          Number(specilatyId),
          specialName,
          Number(subjectId),
          subjectName,
          Number(studyLevelId),
          studyLevelName,
          Number(teacherId),
          teacherName
        ))
    )
  }, [studyLevelId, specilatyId, subjectId, teacherId])

  function closeModal() {
    setShowModal(false)
  }
  function openModal() {
    setShowModal(true)
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className={`${
          t("header.chooseLanguage") === "Ar"
            ? "px-1 py-2 pr-5"
            : "px-4 py-2 pr-11"
        }  relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 rounded-full shadow-lg`}
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        <div className={`${
                              t("header.chooseLanguage") === "Ar"
                                ? " flex-row-reverse text-right mr-3 "
                                : "text-left ml-3"
                            }  flex-1 overflow-hidden`}>
          <span className="block font-medium text-sm">
            {t("heroSearch.searchPlaceholder")}
          </span>
          <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1">
              {t("heroSearch.searchFilters")}
            </span>
          </div>
        </div>

        <span className={`${
                              t("header.chooseLanguage") === "Ar"
                                ? "flex-row-reverse left-2"
                                : "right-2"
                            } absolute flex  top-1/2 transform -translate-y-1/2 w-9 h-9  items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300`}>
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>
    )
  }

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>
                      <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                        {[t("heroSearch.all"), t("heroSearch.teacher")].map(
                          (item, index) => (
                            <Tab key={index} as={Fragment}>
                              {({ selected }) => (
                                <div
                                  className="relative focus:outline-none focus-visible:ring-0 outline-none select-none"
                                  onClick={() => setCurrentTab(item)}
                                >
                                  <div
                                    className={`${
                                      selected
                                        ? "text-black dark:text-white"
                                        : ""
                                    }  `}
                                  >
                                    {item}
                                  </div>
                                  {selected && (
                                    <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                  )}
                                </div>
                              )}
                            </Tab>
                          )
                        )}
                      </Tab.List>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <SearchByCourse
                                setStudyLevelId={(val) => setStudyLevelId(val)}
                                setSpecilatyId={(val) => setSpecilatyId(val)}
                                setSubjectId={(val) => setSubjectId(val)}
                                setStudyLevelName={(val) =>
                                  setStudyLevelName(val)
                                }
                                setSpcialName={(val) => setSpcialName(val)}
                                setSubjectName={(val) => setSubjectName(val)}
                              />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <SearchByTeacher
                                setTeacherId={(val) => setTeacherId(val)}
                                setTeacherName={(val) => setTeacherName(val)}
                              />
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false)
                            resetIsShowingDialog()
                          }}
                        >
                          {t("header.buttons.resetFilters")}
                        </button>
                        {currentTab === t("heroSearch.all") ? (
                          <ButtonSubmit
                            onClick={() => {
                              closeModal()
                            }}
                            href={url}
                            disable={false}
                          />
                        ) : (
                          <ButtonSubmit
                            onClick={() => {
                              closeModal()
                            }}
                            disable={teacherId ? false : true}
                            href={url}
                          />
                        )}
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default HeroSearchForm2Mobile
