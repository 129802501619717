import React, { FC, useEffect, useState } from "react";
import StudyLevelsInput from "../StudyLevelInput";
import { ApiService } from "service/ApiService";
import SpecialtiesInput from "../SpecilatiesInput";
import { SpecialtiesTypes, StudyLevelsTypes, SubjectTypes } from "data/types";
import SubjectsInput from "../SubjectsInput";
import { regularExpressFunction } from "utils/regularExpress";

const CourseSearchForm: FC<{}> = () => {
  const [studyLevels, setStudyLevels] = useState<StudyLevelsTypes[]>([]);
  const [selectedStudyLevel, setSelectedStudyLevel] = useState<string>("");
  const [studyLevelId, setStudyLevelId] = useState<number | null>(null);
  // specialties
  const [specialties, setSpecialties] = useState<SpecialtiesTypes[]>([]);
  const [selectedSpecialty, setselectedSpecialty] = useState<string>("");
  const [specialtyId, setSpecialtyId] = useState<number | null>(null);
  // Subjects
  const [subjects, setSubjects] = useState<SubjectTypes[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string>("");
  const [subjectId, setSubjectId] = useState<number | null>(null);
  // Search Button Disabled
  const [buttonStatus, setButtonStatus] = useState<boolean>(true);

  const [studyLevelName, setStudyLevelName] = useState<string | null>("");
  const [specialName, setSpcialName] = useState<string | null>("");
  const [subjectName, setSubjectName] = useState<string | null>("");

  useEffect(() => {
    // Load All Study Levels
    ApiService.getAllStudyLevels()
      .then((res) => {
        setStudyLevels(res.data.data);
      })
      .catch((err) => console.log(err));
    // Load All Specialties
    ApiService.getAllSpecialties()
      .then((res) => {
        setSpecialties(res.data.data);
      })
      .catch((err) => console.log(err));

    // Load All Specialties
    ApiService.getAllSubjects()
      .then((res) => {
        setSubjects(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 items-center ">
        <SubjectsInput
          className="flex-1"
          onChange={(value: string) => {
            setSelectedSubject(value);
            setSubjectName(value);
            subjects.map((sub) => {
              if (sub.name == value) {
                setSubjectId(sub.id);
              }
            });
          }}
          value={selectedSubject}
          data={subjects}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StudyLevelsInput
          className="flex-1"
          value={selectedStudyLevel}
          onChange={(value: string) => {
            setSelectedStudyLevel(value);
            setStudyLevelName(value);
            studyLevels.map((std) => {
              if (value === std.name) {
                setSpecialties(std.specialties);
                setStudyLevelId(std.id);
              }
            });
          }}
          data={studyLevels}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <SpecialtiesInput
          className="flex-1"
          onChange={(value: string) => {
            setselectedSpecialty(value);
            setSpcialName(value);
            specialties.map((sp) => {
              if (sp.name === value) {
                setSpecialtyId(Number(sp.id));
              }
            });
          }}
          value={selectedSpecialty}
          data={specialties}
          buttonSubmitHref={`/search${
            studyLevelId && studyLevelName
              ? `${`/studylevel/${regularExpressFunction(
                  studyLevelName
                )}/${studyLevelId}`}`
              : ""
          }${
            specialtyId && specialName
              ? `/specialty/${regularExpressFunction(
                  specialName
                )}/${specialtyId}`
              : ""
          }${
            subjectId && subjectName
              ? `/subject/${regularExpressFunction(subjectName)}/${subjectId}`
              : ""
          }`}
          buttonStateData={{
            studyLevelId: studyLevelId,
            specialtyId: specialtyId,
            subjectId: subjectId,
          }}
          buttonIsDisabled={false}
        />
      </form>
    );
  };
  return renderForm();
};

export default CourseSearchForm;
