import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { userSessionType } from "data/types";

interface AuthContextType {
  user: userSessionType | null;
  setSession: (userData: userSessionType) => void;
  clearSession: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);
const SESSION_COOKIE_NAME = "user_session";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<userSessionType | null>(null);

  const setSession = (userData: any) => {
    setUser(userData);

    Cookies.set(SESSION_COOKIE_NAME, JSON.stringify(userData), { expires: 7 });
  };

  const clearSession = () => {
    setUser(null);
    Cookies.remove(SESSION_COOKIE_NAME);
    window.location.href = "/login";
  };

  useEffect(() => {
    let session = Cookies.get(SESSION_COOKIE_NAME);

    if (session) {
      setUser(JSON.parse(session));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setSession, clearSession }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
