import React from "react"
import { Link } from "react-router-dom"
import Avatar from "shared/Avatar/Avatar"
import Badge from "shared/Badge/Badge"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import Comment from "shared/Comment/Comment"
import NcImage from "shared/NcImage/NcImage"
import SocialsList from "shared/SocialsList/SocialsList"
import Textarea from "shared/Textarea/Textarea"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

const BlogSingle = () => {
  const { t } = useTranslation("global")

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name={t("blogSingle.header.badge")} />
          <h1 className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl">
            {t("blogSingle.header.title")}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {t("blogSingle.header.description")}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11"
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    {t("blogSingle.header.author")}
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {t("blogSingle.header.date")}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {t("blogSingle.header.readingTime")}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    )
  }

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h3>{t("blogSingle.content.section1.title")}</h3>
        <p>{t("blogSingle.content.section1.paragraph")}</p>
        <h2>{t("blogSingle.content.section2.title")}</h2>
        <p>{t("blogSingle.content.section2.paragraph")}</p>
        <h2>{t("blogSingle.content.section3.title")}</h2>
        <p>{t("blogSingle.content.section3.paragraph")}</p>
        <p>{t("blogSingle.content.conclusion")}</p>{" "}
        {/* Pas de "title", c'est directement le texte */}
      </div>
    )
  }

  const renderTags = () => {
    const tags = t("blogSingle.tags", { returnObjects: true }) as string[]
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {tags.map((tag: string, index: number) => (
          <a
            key={index}
            className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100 md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
            href="##"
          >
            {tag}
          </a>
        ))}
      </div>
    )
  }

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>{t("blogSingle.seoTitle")}</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12"
        src="https://images.unsplash.com/photo-1585974738771-84483dd9f89f?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  )
}

export default BlogSingle
