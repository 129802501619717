import React, { FC } from "react"
import Heading from "components/Heading/Heading"
import { useTranslation } from "react-i18next"

export interface Statistic {
  id: string
  heading: string
  subHeading: string
}

export interface SectionStatisticProps {
  className?: string
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  const { t } = useTranslation("global")

  const FOUNDER_DEMO: Statistic[] = t("sectionStatistic.stats", {
    returnObjects: true
  }) as Statistic[]

  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading desc={t("sectionStatistic.description")}>
        {t("sectionStatistic.heading")}
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionStatistic
