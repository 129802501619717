import React from "react"
import { Helmet } from "react-helmet"

const Documentation = () => {
  return (
    <div className="nc-PageDocumentation pt-8 lg:pt-16">
      <Helmet>
        <title>Documentation || CoursPlusPlus</title>
      </Helmet>
      <div className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1 className="text-neutral-900  font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl">
            Documentation CoursPlusPlus 📄
          </h1>
          <p className="text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Votre guide pour naviguer sur CoursPlusPlus.
          </p>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </div>

      <div className="container space-y-10 my-10">
        <div className="nc-SingleContent max-w-screen-md mx-auto space-y-10">
          {/* Getting Started */}
          <section>
            <h2 className="font-semibold text-2xl">Commencer</h2>
            <p>
              Introduction au fonctionnement de CoursPlusPlus pour les étudiants
              et les enseignants.
            </p>
          </section>

          {/* For Students */}
          <section>
            <h2 className="font-semibold text-2xl">Pour les Étudiants</h2>
            <p>
              Comment créer un compte, choisir des cours et réserver des
              créneaux.
            </p>
            <ul className="list-disc pl-5">
              <li>
                Création de compte sur <strong> www.coursplusplus.com </strong>{" "}
              </li>
              <li>Choix des cours et visualisation des créneaux disponibles</li>
              <li>
                Réservation de créneaux et accès à l'espace étudiant sur
                <strong> www.coursplusplus.com </strong>{" "}
              </li>
              <li>Suivi des cours réservés et gestion du calendrier</li>
            </ul>
          </section>

          {/* For Teachers */}
          <section>
            <h2 className="font-semibold text-2xl">Pour les Enseignants</h2>
            <p>
              Instructions pour créer un compte, mettre en place des cours, et
              suivre les progrès des étudiants.
            </p>
            <ul className="list-disc pl-5">
              <li>
                Création de compte enseignant sur{" "}
                <strong> www.coursplusplus.com </strong>{" "}
              </li>
              <li>
                Configuration des cours sur{" "}
                <strong>dashboard.coursplusplus.com </strong>
              </li>
              <li>
                Gestion des inscriptions des étudiants et publication des
                créneaux de cours
              </li>
              <li>Accès aux statistiques et suivi des progrès des étudiants</li>
            </ul>
          </section>

          {/* Additional Resources */}
          <section>
            <h2 className="font-semibold text-2xl">
              Ressources Supplémentaires
            </h2>
            <p>
              Liens vers des guides détaillés, des tutoriels vidéo, et une FAQ
              pour vous aider à tirer le meilleur parti de CoursPlusPlus.
            </p>
            {/* Assume there are links or components directing to additional resources */}
          </section>

          {/* Support */}
          <section>
            <h2 className="font-semibold text-2xl">Support Technique</h2>
            <p>
              Pour toute assistance technique ou questions, veuillez consulter
              notre page de support ou nous contacter directement.
            </p>
            {/* Provide link to Support Page or contact information */}
          </section>
        </div>
      </div>
    </div>
  )
}

export default Documentation
