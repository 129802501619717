import StartRating from "components/StartRating/StartRating"
import {
  CourseDetailPageTypes,
  TeacherDataTypes,
  userEnrollmentDetailsType
} from "data/types"
import React, { FC, useEffect, useState } from "react"
import { ApiService } from "service/ApiService"
import { useAuth } from "service/Auth"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import NcImage from "shared/NcImage/NcImage"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export interface PayPageProps {
  className?: string
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const { user } = useAuth()

  const [userEnrollmentDetails, setUserEnrollmentDetails] = useState<
    userEnrollmentDetailsType[]
  >([])
  const [course, setCourse] = useState<CourseDetailPageTypes>()
  const [teacher, setTeacher] = useState<TeacherDataTypes>()
  const [paymentStatus, setPaymentStatus] = useState<boolean | null>()

  useEffect(() => {
    if (user) {
      ApiService.userEnrollmentDetails(user.token, user.id)
        .then((res) => {
          setUserEnrollmentDetails(res.data.data)

          const lastIndex = res.data.data.length - 1

          res.data.data.map((en: any, index: number) => {
            if (index === lastIndex) {
              ApiService.confirmPayment(
                user.token,
                en.stripeInfo.stripeSessionId
              )
                .then((res) => {
                  if (res.data.status === "SUCCESS") {
                    setPaymentStatus(true)
                  } else {
                    setPaymentStatus(false)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          })
        })
        .catch((err) => console.log(err))
    }
  }, [user])

  useEffect(() => {
    if (user) {
      ApiService.getCourseById(
        userEnrollmentDetails[0]?.group?.slots[0]?.courseId
      )
        .then((res) => {
          setCourse(res.data.data)

          const { id } = res.data.data.teacher

          ApiService.getTeacherById(id)
            .then((res) => setTeacher(res.data.data))
            .catch((err) => console.log(err))
        })
        .catch((err) => console.log(err))
    }
  }, [userEnrollmentDetails])

  // Function to convert month number to abbreviated month name
  const getAbbreviatedMonthName = (month: number): string => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]
    return months[month - 1]
  }

  // Function to convert date object to specified format
  const formatDateRange = (date: number[]) => {
    if (!date) {
      return
    }
    const endMonth = getAbbreviatedMonthName(date[1]) // Month is at index 1

    return `${endMonth} ${date[2].toString().padStart(2, "0")}/${date[0]}` // Day is at index 2, year is at index 0
  }

  const formatTime = (time: number[]) => {
    if (!time) return
    const [hour, minute] = time

    // Add leading zero to hours and minutes if needed
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`

    return `${formattedHour}:${formattedMinute}`
  }

  const renderContent = () => {
    if (!paymentStatus)
      return (
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
          <h2 className="text-3xl lg:text-4xl font-semibold bg-gray-200 dark:bg-gray-700 w-full h-8 rounded-md animate-pulse"></h2>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <div className="space-y-6">
            <h3 className="text-2xl font-semibold bg-gray-200 dark:bg-gray-700 w-full h-8 rounded-md animate-pulse"></h3>
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
              </div>
              <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1 bg-gray-200 dark:bg-gray-700 h-4 animate-pulse"></span>
                  <span className="text-base sm:text-lg font-medium mt-1 block bg-gray-200 dark:bg-gray-700 h-8 animate-pulse"></span>
                </div>
                <span className="block  text-sm text-neutral-500 dark:text-neutral-400 bg-gray-200 dark:bg-gray-700 h-4 animate-pulse"></span>
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                <div className="bg-gray-200 dark:bg-gray-700 w-20 h-4 animate-pulse"></div>
              </div>
            </div>
          </div>

          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400 bg-gray-200 dark:bg-gray-700 h-4 animate-pulse"></span>
                <span className="text-sm text-neutral-400 bg-gray-200 dark:bg-gray-700 h-4 animate-pulse"></span>
                <span className="mt-1.5 text-lg font-semibold bg-gray-200 dark:bg-gray-700 h-8 animate-pulse"></span>
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <h3 className="text-2xl font-semibold bg-gray-200 dark:bg-gray-700 w-28 h-8 animate-pulse"></h3>
            <div className="flex flex-col space-y-4">
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1 bg-gray-200 dark:bg-gray-700 w-20 h-4 animate-pulse"></span>

                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100 bg-gray-200 dark:bg-gray-700 w-52 h-4 animate-pulse"></span>
              </div>
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1 bg-gray-200 dark:bg-gray-700 w-24 h-4 animate-pulse"></span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100 bg-gray-200 dark:bg-gray-700 w-80 h-4 animate-pulse"></span>
              </div>
            </div>
          </div>

          <div>
            <button className="bg-gray-200 dark:bg-gray-700 w-28 h-10 animate-pulse"></button>
          </div>
        </div>
      )
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          {paymentStatus
            ? "🎉 Félicitations ! 🎉"
            : paymentStatus === false
            ? "Sorry 😔"
            : "Watting for Payment Status..."}
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">
            Vous venez d'acheter le cours du :{" "}
          </h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage
                  src={`${process.env.REACT_APP_GLOBAL_IMAGE}${teacher?.photos[0].s3Url}`}
                />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {teacher?.role}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {teacher?.firstName + " " + teacher?.lastName}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {teacher?.email}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating
                reviewCount={teacher?.totalTeacherReviews}
                point={teacher?.averageTeacherRating}
              />
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">
                  {userEnrollmentDetails &&
                    userEnrollmentDetails[0]?.group?.slots[0]
                      ?.recurrenceDaysInFrench[0]}
                </span>
                <span className="text-sm text-neutral-400">
                  {userEnrollmentDetails &&
                    formatTime(
                      userEnrollmentDetails[0]?.group?.slots[0].startTime
                    ) +
                      "-" +
                      formatTime(
                        userEnrollmentDetails[0]?.group?.slots[0].endTime
                      )}
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {userEnrollmentDetails &&
                    formatDateRange(
                      userEnrollmentDetails[0]?.group?.slots[0]?.startDate
                    ) +
                      " - " +
                      formatDateRange(
                        userEnrollmentDetails[0]?.group?.slots[0]?.endDate
                      )}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Détails du Cours :</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Titre</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {course?.title}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Description</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {course?.description}
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href="https://dashboard.coursplusplus.com/">
            Aller au Dashboard 🥳
          </ButtonPrimary>
        </div>
      </div>
    )
  }

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  )
}

export default PayPage
