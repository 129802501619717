import React from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

function Navigation() {
  const { t } = useTranslation("global")

  const headerLinks = [
    {
      link: "/",
      name: t("header.navLinks.home")
    },
    {
      link: "/about",
      name: t("header.navLinks.about")
    },
    {
      link: "/contact",
      name: t("header.navLinks.contact")
    },
    {
      link: "/blog",
      name: t("header.navLinks.blog")
    }
  ]

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {headerLinks.map((link, idx) => (
        <li key={idx}>
          <NavLink
            end
            rel="noopener noreferrer"
            className={({ isActive }) =>
              `${
                isActive &&
                "text-neutral-700 bg-neutral-100 dark:bg-neutral-800 dark:text-neutral-200"
              } inline-flex items-center py-2 px-4 rounded-full hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 font-normal text-neutral-6000 dark:text-neutral-300 `
            }
            to={link.link}
          >
            {link.name}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default Navigation
