import React from "react"
import { Helmet } from "react-helmet"

const TermsOfUse = () => {
  return (
    <div className="nc-PageTermsOfUse pt-8 lg:pt-16">
      <Helmet>
        <title>Terms of Use || CoursPlusPlus</title>
      </Helmet>
      <div className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1 className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl">
            Conditions d'Utilisation de CoursPlusPlus
          </h1>
          <p className="text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Bienvenue sur CoursPlusPlus. En accédant à notre site web, vous
            acceptez d'être lié par ces conditions.
          </p>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </div>

      <div className="container space-y-10 my-10">
        <div className="nc-SingleContent max-w-screen-md mx-auto space-y-10">
          {/* Introduction */}
          <section>
            <h2 className="font-semibold text-2xl">1. Introduction</h2>
            <p>
              En utilisant le site web www.coursplusplus.com, vous acceptez
              d'être lié par les présentes conditions d'utilisation, toutes les
              lois et réglementations applicables, et convenez que vous êtes
              responsable de la conformité avec les lois locales applicables.
            </p>
          </section>

          {/* Licence d'Utilisation */}
          <section>
            <h2 className="font-semibold text-2xl">2. Licence d'Utilisation</h2>
            <p>
              Il est permis de télécharger temporairement une copie des
              matériaux (informations ou logiciels) sur le site web de
              CoursPlusPlus pour un visionnement transitoire personnel et non
              commercial seulement. Cette section peut inclure d'autres détails
              spécifiques sur les restrictions de licence.
            </p>
          </section>

          {/* Avertissement */}
          <section>
            <h2 className="font-semibold text-2xl">3. Avertissement</h2>
            <p>
              Les matériaux sur le site web de CoursPlusPlus sont fournis "tels
              quels". CoursPlusPlus ne donne aucune garantie, exprimée ou
              implicite.
            </p>
          </section>

          {/* Limitations */}
          <section>
            <h2 className="font-semibold text-2xl">4. Limitations</h2>
            <p>
              En aucun cas, CoursPlusPlus ou ses fournisseurs ne seront
              responsables de tout dommage découlant de l'utilisation ou de
              l'incapacité à utiliser les matériaux sur le site web de
              CoursPlusPlus.
            </p>
          </section>

          {/* Précision des matériaux */}
          <section>
            <h2 className="font-semibold text-2xl">
              5. Précision des matériaux
            </h2>
            <p>
              Les matériaux apparaissant sur le site web de CoursPlusPlus
              pourraient inclure des erreurs techniques, typographiques, ou
              photographiques. CoursPlusPlus ne garantit pas que l'un des
              matériaux sur son site web sont exacts, complets, ou à jour.
            </p>
          </section>

          {/* Liens */}
          <section>
            <h2 className="font-semibold text-2xl">6. Liens</h2>
            <p>
              CoursPlusPlus n'a pas examiné tous les sites liés à son site web
              et n'est pas responsable du contenu de ces sites liés.
            </p>
          </section>

          {/* Modifications des Conditions d'Utilisation */}
          <section>
            <h2 className="font-semibold text-2xl">
              7. Modifications des Conditions d'Utilisation
            </h2>
            <p>
              CoursPlusPlus peut réviser ces conditions d'utilisation pour son
              site web à tout moment sans préavis.
            </p>
          </section>

          {/* Loi Gouvernante */}
          <section>
            <h2 className="font-semibold text-2xl">8. Loi Gouvernante</h2>
            <p>
              Toute réclamation relative au site web de CoursPlusPlus sera régie
              par les lois du pays du Maroc sans égard à son conflit de
              dispositions légales.
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse
