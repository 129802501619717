import React, { FC } from "react"
import GallerySlider from "components/GallerySlider/GallerySlider"
import { CourseDetailsType, StayDataType } from "data/types"
import StartRating from "components/StartRating/StartRating"
import { Link } from "react-router-dom"
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon"
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge"
import Badge from "shared/Badge/Badge"
import NcImage from "shared/NcImage/NcImage"
import { regularExpressFunction } from "utils/regularExpress"

export interface StayCardProps {
  className?: string
  data: CourseDetailsType
  size?: "default" | "small"
}

const CourseCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data
}) => {
  const {
    id,
    title,
    content,
    description,
    specialties,
    StudyLevel,
    Subject,
    teacher,
    photos,
    totalCourseReviews,
    averageCourseRating
  } = data

  // const renderSliderGallery = () => {
  //   return (
  //     <div className="relative w-full">
  //       <GallerySlider
  //         uniqueID={`StayCard_${id}`}
  //         ratioClass="aspect-w-4 aspect-h-3 "
  //         galleryImgs={galleryImgs}
  //         href={"/"}
  //       />
  //       <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" />
  //       {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
  //     </div>
  //   );
  // };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "" : "space-y-2"}>
        <div>
          <NcImage
            containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
            src={`${process.env.REACT_APP_GLOBAL_IMAGE}${photos[0]?.s3Url}`}
          />
        </div>
        <div className="p-4 space-y-2">
          <div className="space-y-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {/*  {description}*/}
            </span>
            <div className="flex items-center space-x-2">
              {/* {isAds && <Badge name="ADS" color="green" />} */}
              <h2
                className={` font-medium capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                <span className="line-clamp-2">{title}</span>
              </h2>
            </div>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
              {/* {price} */}
              {` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  {/*  {content}*/}
                  Appuez sur le cours pour plus de détails 👆
                </span>
              )}
            </span>
            {!!totalCourseReviews && (
              <StartRating
                reviewCount={totalCourseReviews}
                point={averageCourseRating}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {/* {renderSliderGallery()} */}
      <Link to={`/details/${regularExpressFunction(title)}/${id}`}>
        {renderContent()}
      </Link>
    </div>
  )
}

export default CourseCard
