import React, { FC } from "react"
import NcImage from "shared/NcImage/NcImage"
import { CourseDetailsType } from "data/types"
import { Link } from "react-router-dom"
import { regularExpressFunction } from "utils/regularExpress"

export interface CardCategory3Props {
  className?: string
  taxonomy: CourseDetailsType
}

const CardCategory3: FC<CardCategory3Props> = ({
  className = "",
  taxonomy
}) => {
  const { title, id, photos, teacher } = taxonomy
  return (
    <Link
      to={`/details/${regularExpressFunction(title)}/${id}`}
      className={`nc-CardCategory3 flex flex-col ${className}`}
      data-nc-id="CardCategory3"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={`${process.env.REACT_APP_GLOBAL_IMAGE}${photos[0]?.s3Url}`}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 truncate">
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {title}
        </h2>
      </div>
    </Link>
  )
}

export default CardCategory3
