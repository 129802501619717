import React, { FC } from "react"
import rightImgPng from "images/our-features.png"
import NcImage from "shared/NcImage/NcImage"
import Badge from "shared/Badge/Badge"
import { useTranslation } from "react-i18next"

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  const { t } = useTranslation("global")
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className} `}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`w-full sm:max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          {t("features.subtitle")}
        </span>
        <h2 className="font-semibold text-4xl mt-5">{t("features.title")}</h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name={t("features.badge1")} />
            <span className="block text-xl font-semibold">
              {t("features.title1")}
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {t("features.desc1")}
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name={t("features.badge2")} />
            <span className="block text-xl font-semibold">
              {t("features.title2")}
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {t("features.desc2")}
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name={t("features.badge3")} />
            <span className="block text-xl font-semibold">
              {t("features.title3")}
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {t("features.desc3")}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
