import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import imagePng from "images/hero-right-3.png"
import HeroRealEstateSearchForm from "components/HeroSearchForm/(real-estate-search-form)/HeroRealEstateSearchForm"
export interface SectionHero3Props {
  className?: string
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  const { t } = useTranslation("global")
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div
        className={`absolute z-10 inset-x-0   flex flex-col mt-56 sm:mt-72  lg:mt-96 xl:mt-[30rem] items-center mx-auto space-y-4 lg:space-y-5 px-6 xl:px-0 xl:space-y-8 ${
          t("header.chooseLanguage") === "Ar" ? "text-right" : "text-left"
        }`}
      >
        <h2 className="font-bold text-white  text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl !leading-[115%] whitespace-pre-line w-full   max-w-6xl drop-shadow-lg ">
          {t("mainSection.homePageBannerTitle")}
        </h2>

        {/* <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Keep calm & travel on
        </ButtonPrimary> */}
        <div className="hidden lg:block w-full !mt-30">
          <HeroRealEstateSearchForm className="m-auto" />
        </div>
      </div>

      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-12 xl:aspect-h-12">
        <img
          className="absolute inset-1 object-cover object-center rounded-xl"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  )
}

export default SectionHero3
