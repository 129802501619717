import { regularExpressFunction } from "./regularExpress";

type urlType = (specialId:number | null ,specialName:string | null ,subjectId:number | null,subjectName:string | null,studyLevelId:number | null,studyLevelName:string | null,teacherId:number | null,teacherName:string | null)=> string


export const CheckUrl:urlType = (specialId,specialName, subjectId, subjectName , studyLevelId , studyLevelName, teacherId ,teacherName):string =>{

    
     let app;


     if(teacherId && teacherName){
        app = `search/teacher/${regularExpressFunction(teacherName)}/${teacherId}`;
     }

     if(specialId && specialName){
        app = `search/specialty/${regularExpressFunction(specialName)}/${specialId}`;
     }

     if(subjectId && subjectName){
        app = `search/subject/${regularExpressFunction(subjectName)}/${subjectId}`;
     }

     if(studyLevelId && studyLevelName){
        app = `search/studylevel/${regularExpressFunction(studyLevelName)}/${studyLevelId}`;
     }


     // 3

     if((specialId && subjectId) || (specialName && subjectName)){
        app = `search/specialty/${regularExpressFunction(String(specialName))}/${specialId}/subject/${regularExpressFunction(String(subjectName))}/${subjectId}`;
     }

     if((studyLevelId && specialId) || (studyLevelName && specialName)){
        app = `search/studylevel/${regularExpressFunction(String(studyLevelName))}/${studyLevelId}/specialty/${regularExpressFunction(String(specialName))}/${specialId}`;
     }


     if((studyLevelId && subjectId) || (studyLevelName && subjectName)){
        app = `search/studylevel/${regularExpressFunction(String(studyLevelName))}/${studyLevelId}/subject/${regularExpressFunction(String(subjectName))}/${subjectId}`;
     }



     if((studyLevelId && subjectId && specialId) || (specialName && subjectName && studyLevelName)){
        app = `search/studylevel/${regularExpressFunction(String(studyLevelName))}/${studyLevelId}/specialty/${regularExpressFunction(String(specialName))}/${specialId}/subject/${regularExpressFunction(String(subjectName))}/${subjectId}`;
      }


      
 

     console.log(app)

     return app || "/search";
}