import React, { FC, useEffect, useState, Fragment, ChangeEvent } from "react"
import visaPng from "images/vis.png"
import mastercardPng from "images/mastercard.svg"
import StartRating from "components/StartRating/StartRating"
import NcModal from "shared/NcModal/NcModal"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import { ApiService } from "service/ApiService"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "service/Auth"
import { CourseDetailPageTypes } from "data/types"
import { Tab } from "@headlessui/react"
import { log } from "console"
import { useTranslation } from "react-i18next"

export interface CheckOutPagePageMainProps {
  className?: string
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = ""
}) => {
  const { t } = useTranslation("global")
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [courseDetails, setCourseDetails] = useState<CourseDetailPageTypes>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formErr, setFormErr] = useState<string | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>("STRIPE")
  const [file, setFile] = useState<File | null>(null)
  const [fileUploadedMessage, setFileUploadedMessage] = useState<string | null>(
    null
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { state } = location
  const [isCardPaymentAvailable, setIsCardPaymentAvailable] = useState(false) // false pour indiquer que le paiement est indisponible

  useEffect(() => {
    if (!user && !state) {
      navigate("/")
    }
  }, [user])

  let group_id: number = 0
  let course_id: number = 0
  let package_name: string = ""

  if (state) {
    group_id = state.group_id || 0
    course_id = state.course_id || 0
    package_name = state.package_name || ""
  }

  useEffect(() => {
    ApiService.getCourseById(Number(course_id))
      .then((res) => {
        setCourseDetails(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const handleStripePaymant = () => {
    if (user) {
      setIsLoading(true)
      ApiService.enrollmentsStudent(user.token, user.id, group_id)
        .then((res) => {
          setIsLoading(false)

          if (res.data.status !== "FAIL") {
            const link = res.data.data.stripeInfo.stripeCheckoutUrl

            window.location.href = link
            setFormErr(null)
          } else {
            setFormErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          setFormErr(err.message)
          setIsLoading(false)
        })
    }
  }

  // Function to convert month number to abbreviated month name
  const getAbbreviatedMonthName = (month: number): string => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]
    return months[month - 1]
  }

  // Function to convert date object to specified format
  const getFrenchMonthName = (monthIndex: number): string => {
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre"
    ]
    return months[monthIndex]
  }

  const formatDateRange = (endDate: number[]): string => {
    const endMonth = getFrenchMonthName(endDate[1] - 1) // Mois à l'index 1, ajuster car les mois sont de 0 à 11

    return `${endMonth} ${endDate[2].toString().padStart(2, "0")} ${endDate[0]}` // Jour à l'index 2, année à l'index 0
  }

  const formatTime = (time: number[]) => {
    const [hour, minute] = time

    // Add leading zero to hours and minutes if needed
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`

    return `${formattedHour}:${formattedMinute}`
  }

  const handleStripePayment = () => {
    if (user) {
      setIsLoading(true)
      ApiService.enrollmentsStudent(user.token, user.id, group_id)
        .then((res) => {
          setIsLoading(false)
          if (res.data.status !== "FAIL") {
            const link = res.data.data.paymentInfo.stripeInfo.stripeCheckoutUrl

            window.location.href = link
            setFormErr(null)
          } else {
            setFormErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          setFormErr(err.message)
          setIsLoading(false)
        })
    }
  }

  const handleWireTransferPayment = async () => {
    console.log("User:", user) // Affiche les informations sur l'utilisateur
    console.log("File:", file) // Affiche les informations sur le fichier
    setIsSubmitting(true) // Indique que la soumission a commencé
    if (user && file) {
      setIsLoading(true) // Indique que le chargement est en cours

      try {
        const res = await ApiService.enrollmentsStudentWireTransfer(
          user.token,
          user.id,
          group_id,
          file
        )
        setIsLoading(false) // Arrête l'indicateur de chargement
        if (res.data.status !== "FAIL") {
          navigate("/payment-confirmation") // Redirection vers la page de confirmation
          setFormErr(null) // Réinitialise l'erreur du formulaire si tout est correct
        } else {
          setFormErr(res.data.error) // Affiche l'erreur renvoyée par l'API
        }
      } catch (err) {
        setIsLoading(false)
        if (err instanceof Error) {
          setFormErr(err.message)
        } else {
          setFormErr("Une erreur inconnue est survenue.")
        }
      } finally {
        setIsSubmitting(false)
      }
    } else {
      setFormErr("Veuillez fournir un fichier et être connecté.")
      setIsSubmitting(false)
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log("File selected:", e.target.files) // Vérifiez que cet affichage se produit
    const uploadedFile = e.target.files && e.target.files[0]
    if (uploadedFile) {
      setFile(uploadedFile)
      setFileUploadedMessage(
        `${uploadedFile.name} a été téléchargé avec succès.`
      )
    }
  }

  const renderSidebar = () => {
    // Calcul du prix de l'abonnement
    let subscriptionPrice = 0

    if (courseDetails?.groups) {
      const selectedGroup = courseDetails.groups.find(
        (grp) => grp.id === group_id
      )
      if (selectedGroup) {
        subscriptionPrice = selectedGroup.subscription.price
      }
    }

    // Calcul des frais de service
    const serviceFee = subscriptionPrice * 0.07
    const totalPrice = subscriptionPrice + serviceFee

    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:items-left">
          <div className="flex-shrink-0 w-full">
            <h1 className="text-3xl font-medium">
              {t("checkOutPage.subscription")} : {package_name} ✨
            </h1>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                <strong>{t("checkOutPage.courseDescription")}</strong> :{" "}
                {courseDetails?.description}
              </span>
              <span className="text-base font-medium mt-1 block">
                <strong>{t("checkOutPage.coursTitle")}</strong> :{" "}
                {courseDetails?.title}
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-1 block">
                <strong>{t("checkOutPage.teacher")}</strong> :{" "}
                {courseDetails?.teacher?.firstName}{" "}
                {courseDetails?.teacher?.lastName}
              </span>

              {courseDetails?.studyLevel && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-2 block">
                  <strong>{t("checkOutPage.studyLevel")}:</strong>{" "}
                  {courseDetails.studyLevel.name}
                </span>
              )}

              {courseDetails?.specialties &&
                courseDetails.specialties.length > 0 && (
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-2 block">
                    <strong>{t("checkOutPage.specialities")}:</strong>{" "}
                    {courseDetails.specialties.map((speciality, idx) => (
                      <span key={idx}>
                        {speciality.name}
                        {idx < courseDetails.specialties.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </span>
                )}
            </div>
            <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
            <StartRating
              point={courseDetails?.averageCourseRating}
              reviewCount={courseDetails?.totalCourseReviews}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("checkOutPage.price")}</span>
            <span>
              {subscriptionPrice} {t("checkOutPage.currency")}
            </span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>{t("checkOutPage.serviceFee")}</span>
            <span>
              {serviceFee.toFixed(2)} {t("checkOutPage.currency")}
            </span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>{t("checkOutPage.totalPrice")}</span>
            <span>
              {totalPrice.toFixed(2)} {t("checkOutPage.currency")} /{" "}
              {t("checkOutPage.perMonth")}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          {t("checkOutPage.confirmationAndPayment")}{" "}
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold pb-2">
              {t("checkOutPage.yourSessions")}{" "}
            </h3>
            <div className="text-sm text-neutral-400 flex gap-2 pb-2">
              <span>{t("checkOutPage.cancelAnytime")}</span>
            </div>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  {t("checkOutPage.viewCourseDetails")}{" "}
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Details de votre cours"
            />
          </div>
          <div className="mt-6 border  border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col  divide-y    divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10 p-4">
            {courseDetails?.groups.map((grp, idx) => {
              if (grp.id === group_id) {
                return grp.slots.map((slt, idx) => (
                  <div key={idx} className="flex items-center gap-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <div className={`flex flex-col gap-1  `}>
                      <div className="text-sm dark:text-white flex gap-2 pt-2">
                        <div className="capitalize text-lowercase flex flex-col w-[90px]">
                          {t("checkOutPage.every")} :{" "}
                          {slt.recurrenceDaysInFrench.map((day, idx) => (
                            <span key={idx}>
                              {day}
                              {idx + 1 === slt.recurrenceDaysInFrench.length
                                ? ""
                                : ","}
                            </span>
                          ))}
                        </div>
                        <span>
                          {formatTime(slt.startTime) +
                            "-" +
                            formatTime(slt.endTime)}
                        </span>
                      </div>

                      <div className="text-sm text-neutral-400 flex gap-2 pb-2">
                        <span className="w-[90px]">
                          {t("checkOutPage.until")}
                        </span>
                        <span>{formatDateRange(slt.endDate)}</span>
                      </div>
                    </div>
                  </div>
                ))
              }
            })}
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">
            {t("checkOutPage.securePayment")}
          </h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
          <span className="text-red-500 text-lg">{formErr}</span>
          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-600 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      {t("checkOutPage.wireTransfer")}
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-600 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="flex items-center">
                        <img
                          className="w-9 h-3 mr-0"
                          src={visaPng}
                          alt="Visa"
                        />
                        <img
                          className="w-9 h-8 mr-0"
                          src={mastercardPng}
                          alt="Mastercard"
                        />
                        {t("checkOutPage.creditCard")}
                      </span>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <div>
                      <span className="text-lg font-semibold">
                        {t("checkOutPage.paymentProof")}
                      </span>
                      <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                        {t("checkOutPage.transferProof")}
                      </p>

                      <div className="mt-4">
                        <span className="text-lg font-semibold">
                          {t("checkOutPage.bankDetails")}
                        </span>
                        <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                          {t("checkOutPage.accountHolder")}: Mr AHMED ACHABBAK
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          IBAN: MA64 - 164727211110362132002184
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          BIC/SWIFT: BCPOMAMC
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          {t("checkOutPage.bankName")}: Banque Populaire
                        </p>
                      </div>

                      <div className="mt-4">
                        <span className="text-lg font-semibold">
                          {t("checkOutPage.moneyTransferDetails")}
                        </span>
                        <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                          {t("checkOutPage.recipientName")}: Mr AHMED ACHABBAK
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          {t("checkOutPage.recipientAddress")}: Tetouan
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          {t("checkOutPage.recipientCountry")}: Maroc
                        </p>
                        {/* Affichage du message de succès après téléchargement 

                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          {t("checkOutPage.recipientPhone")}: 06
                        </p>
                        */}
                        <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                          {t("checkOutPage.moneyTransferServices")}
                        </p>
                      </div>

                      <div className="mt-5">
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-neutral-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex flex-col items-center text-sm text-neutral-600 dark:text-neutral-300">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                              >
                                <span>{t("checkOutPage.uploadFile")}</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  accept=".png, .jpg, .jpeg, .gif"
                                  className="sr-only"
                                  onChange={handleFileChange}
                                />
                              </label>
                              <p className="mt-1">
                                {t("checkOutPage.orDragDrop")}
                              </p>
                            </div>
                            <p className="text-xs text-neutral-500 dark:text-neutral-400">
                              PNG, JPG, GIF {t("checkOutPage.fileLimit")}
                            </p>
                          </div>
                        </div>

                        {/* Affichage du message de succès après téléchargement */}
                        {fileUploadedMessage && (
                          <p className="text-sm text-green-600 mt-3">
                            {fileUploadedMessage}
                          </p>
                        )}
                        {formErr && (
                          <span className="text-red-500 text-lg">
                            {formErr}
                          </span>
                        )}

                        <div className="pt-8">
                          <ButtonPrimary
                            onClick={handleWireTransferPayment}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <svg
                                className="animate-spin h-5 w-5 text-white inline-block"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8v8H4z"
                                ></path>
                              </svg>
                            ) : (
                              t("checkOutPage.confirmAndEnroll")
                            )}
                          </ButtonPrimary>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  {!isCardPaymentAvailable ? (
                    // Message d'indisponibilité avec indication de disponibilité prochaine
                    <div className="text-red-500 text-center my-4">
                      {t("checkOutPage.cardPaymentComingSoon")}
                    </div>
                  ) : !isLoading ? (
                    <ButtonPrimary
                      className="!bg-transparent border-neutral-200 dark:border-neutral-700 hover:shadow-lg border"
                      onClick={handleStripePayment}
                    >
                      <span className="mr-2.5 text-black dark:text-white">
                        {t("checkOutPage.confirmCardPayment")}
                      </span>
                    </ButtonPrimary>
                  ) : (
                    <span className="nc-Button text-black dark:text-white relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-600 hover:bg-primary-700 !bg-transparent border-neutral-200 dark:border-neutral-700 hover:shadow-lg border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 dark:focus:ring-offset-0">
                      {t("checkOutPage.loading")}
                    </span>
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="lg:block flex-grow lg:w-2/3 xl:w-2/3">
          {renderMain()}
        </div>
        <div className="w-full lg:w-1/3 xl:w-1/3 lg:pl-10 lg:block hidden">
          {renderSidebar()}
        </div>
      </main>
    </div>
  )
}

export default CheckOutPagePageMain
