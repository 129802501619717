import React from "react";
import ReactDOM from "react-dom/client";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
// Strip
import "@stripe/stripe-js";

import "react-loading-skeleton/dist/skeleton.css";
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "service/Auth";

// Languages
import { I18nextProvider } from "react-i18next";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import global_ar from "./locales/ar/global.json";
import global_fr from "./locales/fr/global.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    lng: "auto",
    fallbackLng: "fr",
    resources: {
      fr: {
        global: global_fr,
      },
      ar: {
        global: global_ar,
      },
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);





root.render(
  //<React.StrictMode>
  <AuthProvider>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </AuthProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
