import rightImg from "images/hero-right.png"
import React from "react"
import SectionHero from "./SectionHero"
import { useTranslation } from "react-i18next"

const SectionFounder = () => {
  const { t } = useTranslation("global")

  return (
    <div className="nc-SectionFounder relative">
      <SectionHero
        rightImg={rightImg}
        heading={t("sectionFounder.heading")}
        btnText=""
        subHeading={<span>{t("sectionFounder.subHeading")}</span>}
      />
      {/* 
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    */}
    </div>
  )
}

export default SectionFounder
