import { BeakerIcon, ClockIcon } from "@heroicons/react/24/outline"
import React, { useState, useRef, useEffect, FC, ChangeEvent } from "react"
import ClearDataButton from "./ClearDataButton"
import { SpecialtiesTypes } from "data/types"
import ButtonSubmit from "./ButtonSubmit"
import { useTranslation } from "react-i18next"

export interface LocationInputProps {
  placeHolder?: string
  desc?: string
  className?: string
  divHideVerticalLineClass?: string
  autoFocus?: boolean
  onChange: (value: string) => void
  value: string
  data: SpecialtiesTypes[]
  buttonSubmitHref: string
  buttonStateData: any
  buttonIsDisabled: boolean
}

export interface CategoriesType {
  id: Number | String
  name: String
}

const CityInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "search.placeholderSpecialty",
  desc = "search.descriptionSpecialty",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onChange,
  value,
  data,
  buttonSubmitHref,
  buttonStateData,
  buttonIsDisabled
}) => {
  const { t } = useTranslation("global")
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [showPopover, setShowPopover] = useState(autoFocus)

  useEffect(() => {
    setShowPopover(autoFocus)
  }, [autoFocus])

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv)
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv)
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv)
    }
  }, [showPopover])

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus()
    }
  }, [showPopover])

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return
    }
    setShowPopover(false)
  }

  const handleSelectLocation = (item: string) => {
    onChange(item)
    setShowPopover(false)
  }

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          {t("search.suggestions")}
        </h3>
        <div className="mt-2">
          {data?.map((item) => (
            <span
              onClick={() => handleSelectLocation(String(item.name))}
              key={Number(item.id)}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <BeakerIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))}
        </div>
      </>
    )
  }

  const renderSearchValue = () => {
    return (
      <>
        {data
          ?.filter((catName) =>
            catName.name.toLowerCase().includes(value.toLowerCase())
          )
          .map((item) => (
            <span
              onClick={() => handleSelectLocation(String(item.name))}
              key={Number(item.id)}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))}
      </>
    )
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange(value)
  }

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`${
          t("header.chooseLanguage") === "Ar" ? "gap-4 !text-right" : ""
        } flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <BeakerIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={t(placeHolder)}
            value={value}
            autoFocus={showPopover}
            onChange={handleChange}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">
              {!!value ? t(placeHolder) : t(desc)}
            </span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                onChange("")
              }}
            />
          )}
        </div>
        <div
          className={`${
            t("header.chooseLanguage") === "Ar" ? "m-auto" : "pr-2 xl:pr-4"
          } `}
        >
          <ButtonSubmit
            href={buttonSubmitHref}
            state={buttonStateData}
            disabled={buttonIsDisabled}
          />
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? renderSearchValue() : renderRecentSearches()}
        </div>
      )}
    </div>
  )
}

export default CityInput
