import Button, { ButtonProps } from "shared/Button/Button"
import React from "react"

export interface ButtonPrimaryProps extends ButtonProps {}

// Bouton Violet
export const ButtonViolet: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonViolet disabled:bg-opacity-70 bg-purple-600 hover:bg-purple-700 text-neutral-50 ${className}`}
      {...args}
    />
  )
}

// Bouton Bleu
export const ButtonBlue: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonBlue disabled:bg-opacity-70 bg-blue-600 hover:bg-blue-700 text-neutral-50 ${className}`}
      {...args}
    />
  )
}

// Bouton Jaune
export const ButtonYellow: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonYellow disabled:bg-opacity-70 bg-yellow-500 hover:bg-yellow-600 text-neutral-900 ${className}`}
      {...args}
    />
  )
}

export const ButtonGreen: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonGreen disabled:bg-opacity-70 bg-green-600 hover:bg-green-700 text-neutral-50 ${className}`}
      {...args}
    />
  )
}
// Bouton par défaut (déjà existant) en couleur primaire
const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      {...args}
    />
  )
}

export default ButtonPrimary
