import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyRouter from "routers/index";

function App() {
  const { t, i18n } = useTranslation("global");
  const [langCheck, setLangCheck] = useState<string>("");

  useEffect(() => {
    i18n.on("languageChanged", function (language: string) {
      if (language == "ar") {
        document.body.classList.add("font-Changer-arabic");
        console.log("arabic");
      } else if (language == "fr") {
        console.log("fr");
        document.body.classList.remove("font-Changer-arabic");
      }
    });

    return () => {
      i18n.off("languageChanged", function () {
        console.log("off");
      });
    };
  }, [i18n]);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
