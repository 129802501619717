import axios from "axios"
import { ContactFormData } from "../data/types"

const baseUrl = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export default baseUrl
const endPoints = {
  allCourses: "/courses",
  // GetStudyLevels
  allStudyLevels: "/courses/study-levels",
  // Specialties
  allSpecialties: "/courses/specialties",
  // get All Subjects
  allSubjects: "/courses/subjects",
  // get All Teachers
  allTeachers: "/teachers",
  // Search Api
  search: "/courses/search",
  // Auth
  authTeacher: "/teachers",
  authStudent: "/students",
  // Login
  login: "auth/login",
  // /enrollments
  enrollmentsStudent: "/enrollments",
  // user enrollment details
  enrollmentDetailByuserId: "/enrollments/student/",
  // Review
  review: "/review",

  contact: "/contact",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password"
}

export const ApiService = {
  forgotPassword: (email: string) => {
    return baseUrl.post(endPoints.forgotPassword, { email })
  },

  resetPassword: (token: string, newPassword: string) => {
    // Le token doit être passé dans l'URL en tant que paramètre de requête
    return baseUrl.post(`${endPoints.resetPassword}?token=${token}`, {
      newPassword
    })
  },
  resendVerificationToken: (email: string) => {
    return baseUrl.post("/auth/resend-verification-token", { email })
  },
  verifyEmail: (token: string) => {
    return baseUrl.get(`/auth/verify-email?token=${token}`)
  },
  getAllCourses: () => {
    return baseUrl.get(endPoints.allCourses)
  },

  getAllStudyLevels: () => {
    return baseUrl.get(endPoints.allStudyLevels)
  },

  getAllSpecialties: () => {
    return baseUrl.get(endPoints.allSpecialties)
  },

  getAllTeachers: () => {
    return baseUrl.get(endPoints.allTeachers)
  },

  getAllSubjects: () => {
    return baseUrl.get(endPoints.allSubjects)
  },

  listingSearch: (
    studylevelid: number,
    specialtyid: number,
    subjectid: number
  ) => {
    const queryParams = new URLSearchParams()

    if (studylevelid) {
      queryParams.append("studyLevelId", String(studylevelid))
    }
    if (specialtyid) {
      queryParams.append("specialtyId", String(specialtyid))
    }
    if (subjectid) {
      queryParams.append("subjectId", String(subjectid))
    }

    const queryString = queryParams.toString()

    return baseUrl.get(
      `${endPoints.search}${queryString ? `?${queryString}` : ""}`
    )
  },

  getTeacherById: (teacherId: number) => {
    return baseUrl.get(endPoints.allTeachers + "/" + teacherId)
  },

  getCourseById: (courseId: number | undefined) => {
    return baseUrl(endPoints.allCourses + `/${courseId}`)
  },

  // Singup as Teacher
  signupTeacher: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    phone: number,
    address: string,
    city: string,
    photos: any,
    birthDate: string,
    gender: string,
    aboutMe: string
  ) => {
    const formData = new FormData()
    formData.append("firstName", firstName)
    formData.append("lastName", lastName)
    formData.append("email", email)
    formData.append("password", password)
    formData.append("phone", String(phone))
    formData.append("address", address)
    formData.append("city", city)
    if (photos) {
      formData.append("photos", photos)
    }
    const date = new Date(birthDate)
    const formattedBirthDate = `${date.getFullYear()}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`

    formData.append("birthDate", formattedBirthDate)
    formData.append("gender", gender)
    formData.append("aboutMe", aboutMe)

    return baseUrl.post(endPoints.authTeacher, formData)
  },

  //  Singup as Student
  signupStudent: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    phone: number,
    address: string,
    city: string,
    photos: any,
    studyLevelId: number,
    specialtyId: number,
    birthDate: string,
    gender: string
  ) => {
    const formData = new FormData()
    formData.append("firstName", firstName)
    formData.append("lastName", lastName)
    formData.append("email", email)
    formData.append("password", password)
    formData.append("phone", String(phone))
    formData.append("address", address)
    formData.append("city", city)
    if (photos) {
      formData.append("photos", photos)
    }
    formData.append("studyLevelId", String(studyLevelId))
    formData.append("specialtyId", String(specialtyId))
    const date = new Date(birthDate)
    const formattedBirthDate = `${date.getFullYear()}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`

    formData.append("birthDate", formattedBirthDate)
    formData.append("gender", gender)

    return baseUrl.post(endPoints.authStudent, formData)
  },

  // Login
  login: (email: string, password: string) => {
    let data = {
      email: email,
      password: password
    }

    return baseUrl.post(endPoints.login, data)
  },

  enrollmentsStudent: (token: string, studentId: number, groupeId: number) => {
    const headers = {
      Authorization: "Bearer " + token
    }

    const formData = new FormData()
    formData.append("studentId", String(studentId))
    formData.append("groupeId", String(groupeId))
    formData.append("PaymentMethod", "STRIPE")

    return baseUrl.post(endPoints.enrollmentsStudent, formData, {
      headers
    })
  },

  enrollmentsStudentWireTransfer: (
    token: string,
    studentId: number,
    groupeId: number,
    file: File
  ) => {
    const headers = {
      Authorization: "Bearer " + token
    }

    const formData = new FormData()
    formData.append("studentId", String(studentId))
    formData.append("groupeId", String(groupeId))
    formData.append("paymentMethod", "WIRE_TRANSFER")
    formData.append("photos", file)

    return baseUrl.post(endPoints.enrollmentsStudent, formData, { headers })
  },

  userEnrollmentDetails: (token: string, user_id: number) => {
    const headers = {
      Authorization: "Bearer " + token
    }

    return baseUrl.get(endPoints.enrollmentDetailByuserId + user_id, {
      headers
    })
  },

  confirmPayment: (token: string, sessionId: string) => {
    const headers = {
      Authorization: "Bearer " + token
    }

    return baseUrl.get(
      endPoints.enrollmentsStudent + `/check-payment/${sessionId}`,
      { headers }
    )
  },

  addReview: (
    token: string,
    studentId: number,
    courseId: number | undefined,
    comment: string,
    rating: number
  ) => {
    const headers = {
      Authorization: "Bearer " + token
    }

    const data = {
      studentId: studentId,
      courseId: courseId,
      comment: comment,
      rating: rating
    }

    return baseUrl.post(endPoints.review, data, { headers })
  },

  sendContactForm: (data: ContactFormData) => {
    return baseUrl.post(endPoints.contact, data)
  }
}
