import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories"
import React, { useEffect, useState } from "react"
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2"
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures"
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork"
import BackgroundSection from "components/BackgroundSection/BackgroundSection"
import { CourseDetailsType, SubjectTypes, TeacherDataTypes } from "data/types"
import HIW1img from "images/HIW2-1.png"
import HIW2img from "images/HIW2-2.png"
import HIW3img from "images/HIW2-3.png"
import HIW1imgDark from "images/HIW2-1-dark.png"
import HIW2imgDark from "images/HIW2-2-dark.png"
import HIW3imgDark from "images/HIW2-3-dark.png"
import rightImgPng from "images/our-features-2.png"
import Avatar from "shared/Avatar/Avatar"
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import NcImage from "shared/NcImage/NcImage"
import convertNumbThousand from "utils/convertNumbThousand"
import { Link } from "react-router-dom"
import { ApiService } from "service/ApiService"
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox"
import Heading from "components/Heading/Heading"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import SectionHero3 from "components/SectionHero/SectionHero3"
import { regularExpressFunction } from "utils/regularExpress"
import { useTranslation } from "react-i18next"

const PageHome = () => {
  const { t } = useTranslation("global")
  const [teacherData, setTeacherData] = useState<TeacherDataTypes[]>([])
  const [subjects, setSubjects] = useState<SubjectTypes[]>([])
  const [courses, setCourses] = useState<CourseDetailsType[]>([])

  useEffect(() => {
    ApiService.getAllTeachers()
      .then((res) => {
        setTeacherData(res.data.data)
      })
      .catch((err) => console.log(err))

    ApiService.getAllSubjects()
      .then((res) => {
        setSubjects(res.data.data)
      })
      .catch((err) => console.log(err))

    ApiService.getAllCourses()
      .then((res) => {
        setCourses(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <div className="max-w-[1200px] m-auto relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero3 />

        <div className="container">
          <SectionHowItWork
            data={[
              {
                id: 1,
                img: HIW2img,
                imgDark: HIW2imgDark,
                title: t("howItWorks.steps.0.title"),
                desc: t("howItWorks.steps.0.desc")
              },
              {
                id: 2,
                img: HIW1img,
                imgDark: HIW1imgDark,
                title: t("howItWorks.steps.1.title"),
                desc: t("howItWorks.steps.1.desc")
              },
              {
                id: 3,
                img: HIW3img,
                imgDark: HIW3imgDark,
                title: t("howItWorks.steps.2.title"),
                desc: t("howItWorks.steps.2.desc")
              }
            ]}
          />

          <SectionOurFeatures type="type2" rightImg={rightImgPng} />

          <div className="relative py-16 mb-24 lg:mb-28">
            <BackgroundSection className="bg-neutral-100 dark:bg-black dark:bg-opacity-20" />
            <div
              className={`nc-SectionGridAuthorBox relative`}
              data-nc-id="SectionGridAuthorBox"
            >
              <Heading desc={t("teachers.description")} isCenter={true}>
                {t("teachers.title")}
              </Heading>
              <div
                className={`grid md:grid-cols-4 sm:grid-cols-3 grid-cols-1 gap-6 md:gap-8`}
              >
                {teacherData?.slice(0, 10).map((teacher) => {
                  return (
                    <div key={teacher.id}>
                      <Link
                        to={`/teacher/${regularExpressFunction(
                          teacher?.firstName + " " + teacher?.lastName
                        )}/${teacher?.id}`}
                        className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow`}
                        data-nc-id="CardAuthorBox2"
                      >
                        <div className="relative flex-shrink-0 ">
                          <div>
                            <NcImage
                              containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
                              src={`${teacher?.backgroundPhoto}`}
                            />
                          </div>
                          <div className="absolute top-3 inset-x-3 flex">
                            <div className="py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
                              {convertNumbThousand(4.5)}
                              <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" />
                            </div>
                          </div>
                        </div>
                        <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                          <svg
                            className="h-12 text-white dark:text-neutral-900 dark:group-hover:text-neutral-800"
                            viewBox="0 0 135 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M101.911 19.8581C99.4421 17.4194 97.15 14.8065 94.6816 12.1935C94.3289 11.671 93.8 11.3226 93.271 10.8C92.9184 10.4516 92.7421 10.2774 92.3895 9.92903C85.8658 3.83226 76.8737 0 67.1763 0C57.4789 0 48.4868 3.83226 41.7868 9.92903C41.4342 10.2774 41.2579 10.4516 40.9053 10.8C40.3763 11.3226 40.0237 11.671 39.4947 12.1935C37.0263 14.8065 34.7342 17.4194 32.2658 19.8581C23.45 28.7419 11.6368 30.4839 0 30.8323V54H16.5737H32.2658H101.734H110.374H134.176V30.6581C122.539 30.3097 110.726 28.7419 101.911 19.8581Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span className="absolute top-2">
                            <Avatar
                              containerClassName=""
                              sizeClass="w-12 h-12 text-2xl"
                              radius="rounded-full"
                              imgUrl={`${
                                teacher?.photos.length !== 0
                                  ? `${process.env.REACT_APP_GLOBAL_IMAGE}${teacher?.photos[0]?.s3Url}`
                                  : ""
                              }`}
                              userName={teacher?.firstName?.charAt(0)}
                            />
                          </span>
                          <div className="mt-6">
                            <h2 className={`text-base font-medium`}>
                              <span className="line-clamp-1">
                                {teacher.firstName}
                              </span>
                            </h2>
                            <span
                              className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
                            >
                              @{teacher.role}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>

              <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
                <ButtonSecondary>
                  {t("teachers.buttonViewMore")}
                </ButtonSecondary>
                <ButtonPrimary href={"/signup/teacher"}>
                  {t("teachers.buttonBecomeTeacher")}
                </ButtonPrimary>
              </div>
            </div>
          </div>

          <SectionSliderNewCategories
            categories={courses}
            heading={t("courses.title")}
            subHeading={t("courses.subTitle")}
            categoryCardType="card5"
            itemPerRow={5}
            uniqueClassName="PageHome2_s2"
            className="mb-16"
          />

          <SectionGridCategoryBox categories={subjects} className="mb-16" />
          <SectionSubscribe2 />
          <br />
        </div>
      </div>
    </div>
  )
}

export default PageHome
