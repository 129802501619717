import React, { FC, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useSearchParams } from "react-router-dom" // Pour récupérer le token depuis l'URL
import { ApiService } from "service/ApiService"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import { useTranslation } from "react-i18next" // Pour la traduction
import swal from "sweetalert"

export interface VerifyEmailProps {
  className?: string
}

const VerifyEmail: FC<VerifyEmailProps> = ({ className = "" }) => {
  const { t } = useTranslation("global") // Hook pour la traduction
  const [searchParams] = useSearchParams() // Récupérer les paramètres de l'URL
  const [message, setMessage] = useState<string>("") // Stocker le message de succès ou d'erreur
  const [messageType, setMessageType] = useState<"success" | "error">("success") // Stocker le type de message (succès ou erreur)
  const [isLoading, setIsLoading] = useState<boolean>(true) // Gérer l'état de chargement
  const [isTokenInvalid, setIsTokenInvalid] = useState<boolean>(false) // Gérer le cas du token invalide
  const [email, setEmail] = useState<string>("") // Pour gérer l'email saisi pour renvoyer le token

  // Récupérer le token depuis l'URL
  const token = searchParams.get("token")

  useEffect(() => {
    const verifyEmailToken = async () => {
      if (token) {
        try {
          // Appel à l'API pour vérifier l'e-mail
          const response = await ApiService.verifyEmail(token)
          const data = response.data

          if (data.status === "SUCCESS") {
            setMessageType("success")
            if (data.details === "L'adresse e-mail a déjà été vérifiée.") {
              setMessage(t("verifyEmail.alreadyVerifiedMessage"))
            } else {
              setMessage(t("verifyEmail.successMessage"))
            }
          } else {
            setMessageType("error")
            setMessage(`${data.error}: ${data.details}`)
            setIsTokenInvalid(true) // Token invalide ou expiré
          }
        } catch (error) {
          setMessageType("error")
          setMessage(t("verifyEmail.errorMessage"))
          setIsTokenInvalid(true) // Token invalide ou erreur d'API
        } finally {
          setIsLoading(false)
        }
      } else {
        setMessageType("error")
        setMessage(t("verifyEmail.missingTokenMessage"))
        setIsTokenInvalid(true) // Aucun token fourni
        setIsLoading(false)
      }
    }

    verifyEmailToken()
  }, [token, t])

  // Fonction pour renvoyer le token de vérification
  const handleResendVerification = async () => {
    if (!email) {
      swal("Erreur", "Veuillez saisir votre adresse e-mail.", "error")
      return
    }

    try {
      setIsLoading(true)
      const response = await ApiService.resendVerificationToken(email)
      const data = response.data

      if (data.status === "SUCCESS") {
        if (data.details === "L'adresse e-mail a déjà été vérifiée.") {
          setMessageType("success")
          setMessage(t("verifyEmail.alreadyVerifiedMessage"))
        } else {
          setMessageType("success")
          swal(
            "Succès",
            "Un nouveau mail de vérification a été envoyé.",
            "success"
          )
        }
      }
    } catch (error) {
      setMessageType("error")
      swal(
        "Erreur",
        "Une erreur s'est produite lors de l'envoi du mail.",
        "error"
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      className={`nc-PageVerifyEmail ${className}`}
      data-nc-id="PageVerifyEmail"
    >
      <Helmet>
        <title>{t("verifyEmail.title")}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <div className="max-w-2xl mt-10 m-auto border border-neutral-200 dark:border-neutral-700 px-4 py-8 rounded-xl relative">
          <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {t("verifyEmail.header")}
          </h2>
          <div className="max-w-md mx-auto space-y-6 text-center">
            {isLoading ? (
              <ButtonPrimary
                disabled
                type="button"
                className="!cursor-not-allowed !pointer-events-none !bg-gray-500"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
                {t("verifyEmail.loading")}
              </ButtonPrimary>
            ) : (
              <p
                className={`${
                  messageType === "success" ? "text-green-600" : "text-red-600"
                }`}
              >
                {message}
              </p>
            )}

            {/* Formulaire de saisie d'email et bouton de renvoi de token */}
            {isTokenInvalid && (
              <>
                <div className="flex justify-center mt-4">
                  <input
                    type="email"
                    placeholder={t("verifyEmail.emailPlaceholder")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-neutral-300 p-2 rounded-md"
                  />
                </div>
                <div className="mt-4">
                  <ButtonPrimary onClick={handleResendVerification}>
                    {t("verifyEmail.resendTokenButton")}
                  </ButtonPrimary>
                </div>
              </>
            )}

            <div className="mt-6">
              <ButtonPrimary href="/login">
                {t("verifyEmail.signInLink")}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
