import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { TeacherDataTypes } from "data/types";
import React, { useState, useEffect, useRef, FC } from "react";
import { ApiService } from "service/ApiService";
import { useTranslation } from "react-i18next"

interface Props {
  onClick?: () => void;
  onChange?: (value: string, id: number) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;

  data: TeacherDataTypes[];
}

const TeachersInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText,
  data,
}) => {
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  let [teachers, setTeacher] = useState<TeacherDataTypes[]>([]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    ApiService.getAllTeachers()
      .then((data) => setTeacher(data.data.data))
      .catch((error) => console.log(error));
  }, []);

  const { t } = useTranslation("global")

  const handleSelectLocation = (item: string, id: number) => {
    // DO NOT REMOVE SETTIMEOUT FUNC

    setTimeout(() => {
      teachers?.map((teacher) => {
        if (item == teacher.firstName + " " + teacher.lastName) {
          setValue(item);
          onChange && onChange(item, id);
        }
      });
    }, 0);
  };

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: any[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => {
            return (
              <div
              className={`${
                t("header.chooseLanguage") === "Ar"
                  ? "gap-x-3"
                  : "space-x-3"
              } py-2 mb-1 flex items-center  text-sm`}
              onClick={() => handleSelectLocation(item.firstName + " " + item.lastName, item.id)}
                key={item.id}
              >
                <EyeIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item.firstName +  " " + item.lastName}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Nom du professeur"}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          <p className="block font-semibold text-base">
            {value ? "Sreach Result" : "Professeurs"}
          </p>
          <div className="mt-3">
          {value
              ? renderSearchValues({
                  heading: "Search Result",
                  items: teachers?.filter((item) =>
                  {
                     const name = `${item.firstName} ${item.lastName}`
                    return name.toLocaleLowerCase().includes(value.toLowerCase())            
                  }
                  ),
                })
              : renderSearchValues({
                  heading: "Ranked Teachers",
                  items: teachers.slice(0, 5),
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeachersInput;
