import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { PiChalkboardTeacherFill, PiStudentFill } from "react-icons/pi"

export interface PageSignUpProps {
  className?: string
}

interface optionTypes {
  icon: JSX.Element
  name: string
  link: string
  description: string
}

const potions: optionTypes[] = [
  {
    icon: <PiStudentFill className="text-5xl" />,
    name: "Student",
    link: "/signup/student",
    description: "Étudiant"
  },
  {
    icon: <PiChalkboardTeacherFill className="text-5xl" />,
    name: "Teacher",
    link: "/signup/teacher",
    description: "Professeur"
  }
]

const SelectSignUpOption: FC<PageSignUpProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Cours PlusPlus</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Définissez votre parcours : êtes-vous professeur ou étudiant ?
        </h2>
        <div className=" mx-auto space-y-6 ">
          <div className="options__select flex gap-4 flex-wrap items-center justify-center">
            {potions.map((option: optionTypes, idx: number) => (
              <Link
                to={option.link}
                key={idx}
                className="sm:w-[400px] flex flex-col items-center text-center sm:rounded-2xl border border-neutral-700 space-y-7 p-4 hover:border-primary-6000"
              >
                {option.icon}
                <p className="text-lg font-bold">{option.description}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectSignUpOption
