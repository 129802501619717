import React, { FC, useEffect, useState } from "react"
import BackgroundSection from "components/BackgroundSection/BackgroundSection"
import { Helmet } from "react-helmet"
import { Link, useParams, useLocation, useSearchParams } from "react-router-dom"
import { ApiService } from "service/ApiService"
import { CourseTypes, TeacherDataTypes } from "data/types"
import Heading from "components/Heading/Heading"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import Avatar from "shared/Avatar/Avatar"
import NcImage from "shared/NcImage/NcImage"
import convertNumbThousand from "utils/convertNumbThousand"
import { StarIcon } from "@heroicons/react/24/solid"
import { regularExpressFunction } from "utils/regularExpress"
import { useTranslation } from "react-i18next"

export interface ListingStayMapPageProps {
  className?: string
}

type Params = undefined | null | string

const ListingPage: FC<ListingStayMapPageProps> = ({ className = "" }) => {
  const { t } = useTranslation("global")
  const location = useLocation()

  let params2 = useParams()

  let studyLevelId: Params = params2?.studyLevelId
  let specialtyId: Params = params2?.specialId
  let subjectId: Params = params2?.subjectId
  let teacherId: Params = params2?.teacherId

  const [courseDetails, setCourseDetails] = useState<CourseTypes[]>([])
  const [teacherData, setTeacherData] = useState<TeacherDataTypes[]>([])
  const [displayCount, setDisplayCount] = useState(20)

  const handleShowMore = () => {
    setDisplayCount((prevCount) => prevCount + 20)
  }

  useEffect(() => {
    if (params2?.teacherId) {
      studyLevelId = null
      specialtyId = null
      subjectId = null
    }
    if (!teacherId) {
      ApiService.listingSearch(
        Number(studyLevelId),
        Number(specialtyId),
        Number(subjectId)
      )
        .then((res) => {
          setCourseDetails([...res.data.data])
          setTeacherData([])
        })
        .catch((err) => console.log(err))
    }
  }, [studyLevelId, specialtyId, subjectId, teacherId])

  useEffect(() => {
    if (teacherId) {
      ApiService.getTeacherById(Number(teacherId))
        .then((res) => {
          setTeacherData([res.data.data])
          if (params2.teacherId) {
            studyLevelId = null
            specialtyId = null
            subjectId = null
          }
          setCourseDetails([])
        })
        .catch((err) => console.log(err))
    }
  }, [teacherId])

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>
          {t("header.listingPage.searchResultsTitle")} || Cours PlusPlus
        </title>
      </Helmet>

      <div className="container overflow-hidden">
        <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-neutral-100 dark:bg-black dark:bg-opacity-20 " />
          <div
            className={`nc-SectionGridAuthorBox relative`}
            data-nc-id="SectionGridAuthorBox"
          >
            <Heading desc={t("listingPage.searchResultsDesc")} isCenter={true}>
              {t("listingPage.searchResults")}
            </Heading>
            <div
              className={`grid md:grid-cols-4 sm:grid-cols-3 grid-cols-1 gap-6 md:gap-8`}
            >
              {courseDetails.length === 0 ? (
                <div className="col-span-full text-center text-neutral-500 dark:text-neutral-400">
                  {t("listingPage.noCoursesFound")}
                </div>
              ) : (
                courseDetails
                  .slice(0, displayCount)
                  .sort((a, b) => b.averageCourseRating - a.averageCourseRating)
                  .map((course) => (
                    <div key={course.id}>
                      <Link
                        to={`/details/${regularExpressFunction(course.title)}/${
                          course.id
                        }`}
                        className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow ${className}`}
                        data-nc-id="CardAuthorBox2"
                      >
                        <div className="relative flex-shrink-0 ">
                          <div>
                            <NcImage
                              containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
                              src={`${process.env.REACT_APP_GLOBAL_IMAGE}${course.photos[0]?.s3Url}`}
                            />
                          </div>
                          <div className="absolute top-3 inset-x-3 flex">
                            <div className="py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
                              {convertNumbThousand(course.averageCourseRating)}{" "}
                              <StarIcon className="w-5 h-5 text-yellow-400 ml-2" />
                            </div>
                          </div>
                        </div>
                        <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                          <Avatar
                            containerClassName=""
                            sizeClass="w-12 h-12 text-2xl"
                            radius="rounded-full"
                            imgUrl={
                              course?.teacher?.photos?.[0]?.s3Url
                                ? `${process.env.REACT_APP_GLOBAL_IMAGE}${course.teacher.photos[0].s3Url}`
                                : ""
                            }
                            userName={
                              course?.teacher?.firstName
                                ? course.teacher.firstName[0]
                                : "N"
                            }
                          />
                          <div className="mt-6">
                            <h2 className={`text-base font-medium`}>
                              <span className="line-clamp-2">
                                {course.title}
                              </span>
                            </h2>
                            <span
                              className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
                            >
                              @{course.teacher.firstName}{" "}
                              {course.teacher.lastName}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
              )}
              {teacherData
                .slice(0, displayCount)
                .sort((a, b) => b.averageTeacherRating - a.averageTeacherRating)
                .map((teacher) => (
                  <div key={teacher.id}>
                    <Link
                      to={`/teacher/${regularExpressFunction(
                        teacher.firstName + " " + teacher.lastName
                      )}/${teacher.id}`}
                      className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow ${className}`}
                      data-nc-id="CardAuthorBox2"
                      state={{ teacherId: teacher.id || teacherId }}
                    >
                      <div className="relative flex-shrink-0 ">
                        <NcImage
                          containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
                          src={`${process.env.REACT_APP_GLOBAL_IMAGE}${teacher?.photos[0]?.s3Url}`}
                        />
                        <div className="absolute top-3 inset-x-3 flex">
                          <div className="py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
                            {convertNumbThousand(teacher.averageTeacherRating)}{" "}
                            <StarIcon className="w-5 h-5 text-yellow-600 ml-2" />
                          </div>
                        </div>
                      </div>
                      <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                        <Avatar
                          containerClassName=""
                          sizeClass="w-12 h-12 text-2xl"
                          radius="rounded-full"
                          imgUrl={`${process.env.REACT_APP_GLOBAL_IMAGE}${teacher?.photos[0]?.s3Url}`}
                          userName={"N"}
                        />
                        <div className="mt-6">
                          <h2 className={`text-base font-medium`}>
                            <span className="line-clamp-1">
                              {teacher.firstName}
                            </span>
                          </h2>
                          <span
                            className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
                          >
                            @{teacher.role}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
            <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
              <ButtonSecondary href={"/"}>
                {t("buttons.backToHome")}
              </ButtonSecondary>
              <ButtonPrimary href={"/signup/teacher"}>
                {t("buttons.becomeTeacher")}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingPage
