import React from "react"
import { Helmet } from "react-helmet"
import Header from "components/Header/Header"

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const renderHeader = () => {
    let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700"

    return <Header className={headerClassName} navType="MainNav1" />
  }

  return (
    <>
      <Helmet>
        <title>
          Cours PlusPlus || Trouvez rapidement un professeur qualifié
        </title>
      </Helmet>

      {renderHeader()}
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  )
}

export default SiteHeader
