import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { SpecialtiesTypes } from "data/types"
import React, { useState, useEffect, useRef, FC } from "react"
import { ApiService } from "service/ApiService"
import { useTranslation } from "react-i18next"

interface Props {
  onClick?: () => void
  onChange?: (value: string, id: number) => void
  className?: string
  defaultValue?: string
  headingText?: string
  helperSpecialties: SpecialtiesTypes[]
}

const SpecilatiesInput: FC<Props> = ({
  onChange = () => {},
  className = ""
}) => {
  const { t } = useTranslation("global")
  const [specialties, setSpecialties] = useState<SpecialtiesTypes[]>([])

  useEffect(() => {
    ApiService.getAllSpecialties()
      .then((res) => {
        setSpecialties(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const [value, setValue] = useState(t("specilatiesInput.defaultValue"))
  const containerRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    setValue(t("specilatiesInput.defaultValue"))
  }, [t])

  const handleSelectLocation = (item: string) => {
    setTimeout(() => {
      setValue(item)
      specialties?.map((splties) => {
        if (item === splties.name) {
          onChange && onChange(item, parseInt(splties.id))
        }
      })
    }, 0)
  }

  const renderSearchValues = ({ heading }: { heading: string }) => {
    return (
      <>
        <p className="block font-semibold text-base">{heading}</p>
        <div className="mt-3">
          {specialties.length > 0
            ? specialties
                .filter((specialty) =>
                  specialty.name.toLowerCase().includes(value.toLowerCase())
                )
                .map((item) => {
                  return (
                    <div
                      className="py-2 mb-1 flex items-center space-x-3 text-sm"
                      onClick={() => handleSelectLocation(item.name)}
                      key={item.id}
                    >
                      <EyeIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                      <span className="">{item.name}</span>
                    </div>
                  )
                })
            : t("specilatiesInput.loading")}
          {specialties.length > 0 &&
            specialties.filter((specialty) =>
              specialty.name.toLowerCase().includes(value.toLowerCase())
            ).length === 0 && <div>{t("specilatiesInput.noValueFound")}</div>}
        </div>
      </>
    )
  }

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {t("specilatiesInput.headingText")}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={t("specilatiesInput.searchPlaceholder")}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {renderSearchValues({
            heading: t("specilatiesInput.headingText")
          })}
        </div>
      </div>
    </div>
  )
}

export default SpecilatiesInput
